<script setup>
import { ref } from 'vue'
import { http, constants, utility } from "../lib";
import HomeProductItem from './components/HomeProductItem.vue'
import Banner from "./components/Banner.vue";
import Footer from "./components/Footer.vue";
const homeProductList = ref([]);

http.get('home_product', {}, res => {
  let list = [];
  res.data.forEach(v => {
    if (!v.enabled) return;
    let item = {
      title: v.use_custom_info ? v.custom_title : v.product_name,
      heading: v.use_custom_info ? v.custom_caption : v.product_caption,
      text: v.use_custom_info ? v.custom_description : v.product_description,
      background: constants.image_root + v.image_name,
      align: v.align,
      margin_left: v.margin_left,
      margin_top: v.margin_top,
      height: v.height,
      buttons: []
    }
    if (v.show_default_link) {
      if (v.overwrite_default_link) {
        let idx = v.default_link_href.indexOf('?')
        if (idx === -1) idx = v.default_link_href.length;
        item.buttons.push({
          type: 0,
          text: v.default_link_name,
          url: v.default_link_href.substring(0, idx),
          query: utility.parseQuery(v.default_link_href)
        })
      } else {
        console.log(v)
        item.buttons.push({
          type: 0,
          text: 'SEE MORE',
          url: 'product_detail',
          query: {
            id: v.product_id
          }
        })
      }
    }
    if (v.extra_link && v.extra_link_name && v.extra_link_href) {
      let idx = v.extra_link_href.indexOf('?')
      if (idx === -1) idx = v.default_link_href.length;
      item.buttons.push({
        type: 1,
        text: v.extra_link_name,
        url: v.extra_link_href.substring(0, idx),
        query: utility.parseQuery(v.extra_link_href)
      })
    }
    list.push(item);
  })

  homeProductList.value = list;
})
</script>

<template>
  <Banner />
  <HomeProductItem :productItem="homeProduct" v-for="homeProduct in homeProductList"></HomeProductItem>
  <Footer />
</template>

<style scoped lang="scss">
</style>
