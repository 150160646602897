<script setup>
import { ref } from 'vue';
import { constants } from "../../lib";
import { ElMessage, ElMessageBox } from 'element-plus'
const props = defineProps({
  cards: {
    type: Array
  },
  id: {
    type: Number
  }
})
const newCard = ref({})
const fileList = ref([]);
const emit = defineEmits(['change'])
const createCard = () => {
  let data = newCard.value;
  if (!data.caption || !data.image_id || !data.description) {
    ElMessage('还有必填字段未填写')
    return;
  }
  newCard.value.product_id = props.id;
  if (props.cards.length > 0) {
    newCard.value.order_no = props.cards[props.cards.length - 1].order_no + 1
  } else {
    newCard.value.order_no = 1
  }

  emit('change', 'create', newCard.value)
  newCard.value = {}
  fileList.value = []
}
const uploadSuccess = function(data) {
  newCard.value.image_id = data.image_id;
  newCard.value.image_name = data.name;
}
const moveUp = (card, index) => {
  let data = Object.assign({ index: index}, card)
  emit('change', 'moveUp', data)
}
const moveDown = (card, index) => {
  let data = Object.assign({ index: index}, card)
  emit('change', 'moveDown', data)
}
const deleteSpec = (card, index) => {
  let data = Object.assign({ index: index}, card)
  emit('change', 'delete', data)
}
</script>

<template>
  <div class="card-container">
    <el-row v-for="(card, index) in cards" :gutter="20" class="row">
      <el-col :span="5">
        <el-form-item>
          <p>{{ card.caption }}</p>
        </el-form-item>
      </el-col>
      <el-col :span="5">
        <el-form-item>
          <p><img class="uploaded-images" :src="constants.image_root + card.image_name"/></p>
        </el-form-item>
      </el-col>
      <el-col :span="10">
        <el-form-item>
          <p>{{ card.description }}</p>
        </el-form-item>
      </el-col>
      <el-col :span="4" style="text-align: left">
        <div class="op">
          <div class="icons">
            <div class="icon">
              <ion-icon name="arrow-up" @click="moveUp(card, index)"></ion-icon>
            </div>
            <div class="icon">
              <ion-icon name="arrow-down" @click="moveDown(card, index)"></ion-icon>
            </div>
            <div class="icon">
              <ion-icon name="close" @click="deleteSpec(card, index)"></ion-icon>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row class="row" :gutter="20">
      <el-col :span="5">
        <el-form-item>
          <el-input placeholder="输入标题" v-model="newCard.caption"/>
        </el-form-item>
      </el-col>
      <el-col :span="5">
        <el-form-item>
          <el-upload
              class="upload"
              :action="constants.upload_url"
              :on-success="uploadSuccess"
              list-type="picture-card"
              :file-list="fileList"
              :multiple="false"
              :limit="1"
          >
            <ion-icon name="add-outline" style="font-size: 28px;"></ion-icon>
          </el-upload>
        </el-form-item>
      </el-col>
      <el-col :span="10">
        <el-form-item>
          <el-input type="textarea" :rows="5" v-model="newCard.description" placeholder="输入描述"/>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <div class="op">
          <el-button class="save-icon" @click="createCard">保存</el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<style lang="scss" scoped>
.card-container {
  width: 100%;
}
.uploaded-images {
  width: 148px;
  max-height: 148px;
  margin-right: 8px;
  border-radius: 4px;
}
.row {
  margin-bottom: 5px;

  .el-form-item {
    text-align: left;
  }

  .save-icon {
    margin-top: 40px;
  }
  .selected-icon {
    display: inline-block;
    float: left;
    width: 48px;
    font-size: 24px;
    cursor: pointer;
    :hover {
      color: #5FAFF1;
    }

    ion-icon {
      vertical-align: -4px;
    }
  }
  .text {
    text-align: left;
    display: inline-block;
    width: calc(100% - 192px);
    input {
      height: 48px;
      line-height: 48px;
      outline: none;
      border: 1px solid #EAEAEA;
      width: 100%;
      color: rgba(96, 98, 102);
      border-radius: 4px;
      box-sizing: border-box;
      padding: 0 15px;
    }
  }
  .op {
    display: inline-block;
    width: 144px;
    .icon {
      display: inline-block;
      float: left;
      width: 36px;
      font-size: 24px;
      cursor: pointer;
      transition: all .5s;
      border-radius: 4px;
      &:hover {
        background: #EFEFEF;
      }

      ion-icon {
        vertical-align: -4px;
      }
    }
  }

  .add-new {
    span {
      font-size: 18px;
      vertical-align: -5px;
      margin-right: 10px;
    }
    ion-icon {
      font-size: 32px;
      vertical-align: -15px;
      cursor: pointer;
    }
  }
}
</style>