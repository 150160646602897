<script setup>
import { ref } from 'vue'
import { ElMessage } from 'element-plus'
import { http, utility, constants } from "../../lib";

let homeProductMode = 'create'; // create / update
const homeProduct = ref({
  product_id: '',
  use_custom_info: false,
  background_image: '',
  custom_title: '',
  custom_caption: '',
  custom_description: '',
  align: 0,
  extra_link: false,
  extra_link_name: '',
  extra_link_href: '',
  sort: 1,
  enabled: true,
  margin_left: 0,
  margin_top: 0,
  show_default_link: true,
  overwrite_default_link: false,
  default_link_name: '',
  default_link_href: '',
  height: 540
})
const homeProductList = ref([])
const productList = ref([])
const fileList = ref([]) // 上传图片

const showHomeProductModal = ref(false);
const showHomeProductCreateTitle = ref('新增首页产品')

const showHomeProductCreate = () => {
  showHomeProductCreateTitle.value = '新增首页产品'
  homeProductMode = 'create'
  homeProduct.value = {
    product_id: '',
    use_custom_info: false,
    background_image: '',
    custom_title: '',
    custom_caption: '',
    custom_description: '',
    align: 0,
    extra_link: false,
    extra_link_name: '',
    extra_link_href: '',
    sort: 1,
    enabled: true,
    margin_left: 0,
    margin_top: 0,
    show_default_link: true,
    overwrite_default_link: false,
    default_link_name: '',
    default_link_href: '',
    height: 540
  };
  showHomeProductModal.value = true;
  fileList.value = [];
}
const showHomeProductEdit = (value) => {
  showHomeProductCreateTitle.value = '编辑首页产品'
  homeProductMode = 'update'
  homeProduct.value = Object.assign({}, value)
  homeProduct.value.use_custom_info = Boolean(value.use_custom_info)
  homeProduct.value.extra_link = Boolean(value.extra_link)
  homeProduct.value.enabled = Boolean(value.enabled)
  homeProduct.value.show_default_link = Boolean(value.show_default_link)
  homeProduct.value.overwrite_default_link = Boolean(value.overwrite_default_link)
  fileList.value = [{
    name: '',
    url: constants.image_root + value.image_name
  }]
  showHomeProductModal.value = true;
}

const uploadSuccess = (data) => {
  homeProduct.value.background_image = data.image_id;
  homeProduct.value.image_name = data.name;
}
const homeProductSaveAction = () => {
  let info = Object.assign({}, homeProduct.value);
  if (!(info.product_id && info.background_image)) {
    ElMessage({
      message: '请选择产品和背景图片',
      type: 'error',
    })
    return;
  }
  if (info.use_custom_info && !(info.custom_title && info.custom_caption && info.custom_description)) {
    ElMessage({
      message: '请填写自定义信息',
      type: 'error',
    })
    return;
  }
  if (info.extra_link && !(info.extra_link_name && info.extra_link_href)) {
    ElMessage({
      message: '请填写额外按钮信息',
      type: 'error',
    })
    return;
  }
  if (info.overwrite_default_link && !(info.default_link_name && info.default_link_href)) {
    ElMessage({
      message: '请填写默认按钮信息',
      type: 'error',
    })
    return;
  }

  if (homeProductMode === 'create') {
    let productIndex = utility.getIndex(productList.value, 'id', homeProduct.product_id);
    let product = productList.value[productIndex];
    info.product_name = product.name;
    http.post('home_product', homeProduct.value, res => {
      if (res.data) {
        homeProductList.value.push(Object.assign(info, res.data));
        showHomeProductModal.value = false;
      }
    })
  }
  if (homeProductMode === 'update') {
    http.put('home_product', homeProduct.value, res => {
      if (res.data) {
        let index = utility.getIndex(homeProductList.value, 'id', homeProduct.value.id);
        homeProductList.value.splice(index, 1, info);
        showHomeProductModal.value = false;
      }
    })
  }
}
const deleteHomeProduct = (value) => {
  http.delete('home_product', { id: value.id }, res => {
    if (res.data) {
      let index = utility.getIndex(homeProductList.value, 'id', value.id);
      homeProductList.value.splice(index, 1);
    }
  })
}

http.get('product', null, res => {
  productList.value = res.data.map(v => {
    return {
      name: v.name,
      product_id: v.id
    }
  })
});
http.get('home_product', null, res => {
  homeProductList.value = res.data;
})
</script>

<template>
<el-container>
  <el-tabs type="border-card">
    <el-tab-pane class="home-product" label="首页产品">
      <el-row>
        <div class="top-operation">
          <el-button type="success" @click="showHomeProductCreate">新增产品</el-button>
        </div>
      </el-row>
      <el-row>
        <el-table :data="homeProductList" class="table-content">
          <el-table-column prop="product_name" label="产品名称" min-width="100px"></el-table-column>
          <el-table-column prop="background_image" label="背景图片" min-width="120px">
            <template #default="scope">
              <p><img class="uploaded-images" :src="constants.image_root + scope.row.image_name"/></p>
            </template>
          </el-table-column>
          <el-table-column prop="align" label="对齐方式" align="center" min-width="100px">
            <template #default="scope">
              <p>{{ utility.format.align.text(scope.row.align) }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="margin_left" label="左边距(px)" min-width="100px" align="center"></el-table-column>
          <el-table-column prop="margin_top" label="上边距(px)" min-width="100px" align="center"></el-table-column>
          <el-table-column prop="use_product_info" label="自定义文字" align="center" min-width="100px">
            <template #default="scope">
              <p>{{ scope.row.use_custom_info ? '是' : '否' }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="extra_link" label="使用外部链接" align="center" min-width="100px">
            <template #default="scope">
              <p>{{ scope.row.extra_link ? '是' : '否' }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="enabled" label="启用状态" align="center" min-width="100px">
            <template #default="scope">
              <p>{{ scope.row.enabled ? '启用' : '禁用' }}</p>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" min-width="160px">
            <template #default="scope">
              <el-button type="primary" @click="showHomeProductEdit(scope.row)">编辑</el-button>
              <el-popconfirm
                  title="确定删除？"
                  confirm-button-text="确定"
                  cancel-button-text="取消"
                  @confirm="deleteHomeProduct(scope.row)"
              >
                <template #reference>
                  <el-button type="danger">删除</el-button>
                </template>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-tab-pane>
  </el-tabs>
  <el-dialog
    v-model="showHomeProductModal"
    :title="showHomeProductCreateTitle"
    width="45%"
    >
    <el-row>
      <el-col :span="22" :offset="1">
        <el-form :modal="homeProduct" label-width="120px">
          <el-form-item label="产品">
            <el-select v-model="homeProduct.product_id" placeholder="选择待展示的产品">
              <el-option
                  :label="product.name"
                  :value="product.product_id"
                  v-for="product in productList">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="使用自定义信息">
            <el-switch v-model="homeProduct.use_custom_info"></el-switch>
          </el-form-item>
          <el-form-item label="自定义标题" v-if="homeProduct.use_custom_info">
            <el-input v-model="homeProduct.custom_title" />
          </el-form-item>
          <el-form-item label="自定义副标题" v-if="homeProduct.use_custom_info">
            <el-input v-model="homeProduct.custom_caption" />
          </el-form-item>
          <el-form-item label="自定义描述" v-if="homeProduct.use_custom_info">
            <el-input v-model="homeProduct.custom_description" type="textarea" :rows="5" />
          </el-form-item>
          <el-form-item label="图片高度">
            <el-input v-model="homeProduct.height" />
          </el-form-item>
          <el-form-item label="背景图片">
            <el-upload
                class="upload"
                :action="constants.upload_url"
                :on-success="uploadSuccess"
                :file-list="fileList"
                list-type="picture-card"
                :limit="1"
            >
              <ion-icon name="add-outline" style="font-size: 28px;"></ion-icon>
            </el-upload>
          </el-form-item>
          <el-form-item label="对齐方式">
            <el-select v-model="homeProduct.align">
              <el-option label="左侧" :value="0"></el-option>
              <el-option label="中间" :value="1"></el-option>
              <el-option label="右侧" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="左边距">
            <el-input-number v-model="homeProduct.margin_left" />
          </el-form-item>
          <el-form-item label="上边距">
            <el-input-number v-model="homeProduct.margin_top" />
          </el-form-item>
          <el-form-item label="显示默认按钮">
            <el-switch v-model="homeProduct.show_default_link"></el-switch>
          </el-form-item>
          <el-form-item label="自定义默认按钮">
            <el-switch v-model="homeProduct.overwrite_default_link"></el-switch>
          </el-form-item>
          <el-form-item label="按钮文字" v-if="homeProduct.overwrite_default_link">
            <el-input v-model="homeProduct.default_link_name" />
          </el-form-item>
          <el-form-item label="按钮跳转链接" v-if="homeProduct.overwrite_default_link">
            <el-input v-model="homeProduct.default_link_href" />
          </el-form-item>
          <el-form-item label="额外按钮">
            <el-switch v-model="homeProduct.extra_link"></el-switch>
          </el-form-item>
          <el-form-item label="按钮文字" v-if="homeProduct.extra_link">
            <el-input v-model="homeProduct.extra_link_name" />
          </el-form-item>
          <el-form-item label="按钮跳转链接" v-if="homeProduct.extra_link">
            <el-input v-model="homeProduct.extra_link_href" />
          </el-form-item>
          <el-form-item label="展示顺序">
            <el-input-number v-model="homeProduct.sort" :min="1" />
          </el-form-item>
          <el-form-item label="启用状态">
            <el-switch v-model="homeProduct.enabled"></el-switch>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="showHomeProductModal = false">取消</el-button>
        <el-button @click="homeProductSaveAction" type="primary">保存</el-button>
      </span>
    </template>
  </el-dialog>
</el-container>
</template>

<style lang="scss" scoped>
.el-tabs {
  width: 100%;
  .el-transfer {
    width: 800px;
  }
  .home-product {
    .uploaded-images {
      width: 180px;
    }
    .top-operation {
      margin-bottom: 20px;
      text-align: right;
      width: 100%;
    }
    .dialog-footer button:first-child {
      margin-right: 10px;
    }
  }
}
</style>