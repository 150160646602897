<script setup>
import { useRouter } from 'vue-router'
const props = defineProps({
  product: {
    type: Object
  }
})
const router = useRouter();
const showDetail = (id) => {
  router.push({ path: '/product_detail', query: { id: id } })
}
</script>

<template>
  <div class="item-card">
    <div class="container" :style="{'--bgcolor': product.color}">
      <div class="caption">
        <div class="wrapper">
          <div class="heading">
            <p class="title">{{ product.title }}</p>
          </div>
          <div class="text">
            <p class="subtitle">{{ product.subtitle }}</p>
          </div>
          <div class="options" v-if="product.skus.length > 0">
            <p class="name">{{ product.skus.length }} {{ product.skus.length > 1 ? 'Colors' : 'Color' }} available</p>
            <div class="content">
              <span v-for="sku in product.skus" class="option" :style="{ background: sku.color }"></span>
            </div>
          </div>
          <div class="button" @click="showDetail(product.id)">
            <span class="more">LEARN MORE</span>
          </div>
        </div>
      </div>
      <div class="spec">
        <ul>
          <li v-for="option in product.spec">
            <ion-icon :name="option.icon" class="icon-item"></ion-icon>
            <span>{{ option.label }}</span>
          </li>
        </ul>
      </div>
      <div class="image">
        <img :src="product.image">
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/common.scss';
.item-card {
  max-width: 1120px;
  margin: 40px auto;
  position: relative;
  background-color: #FFFFFF;
}
.container {
  display: flex;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -20px;
    bottom: 0;
    width: 20px;
    background-color: var(--bgcolor);
  }
  .caption {
    width: 33.333%;
    margin: 15px 0 0 20px;
    padding: 0;
    .wrapper {
      width: 100%;
      display: flex;
      align-content: flex-start;
      flex-wrap: wrap;
    }
    .heading, .text, .options, .button {
      width: 100%;
      text-align: left;
    }
    .heading {
      margin-bottom: 20px;
      .title {
        color: #1D1D1F;
        font-family: "Roboto Condensed", Sans-serif;
        font-size: 40px;
        font-weight: 900;
        text-transform: uppercase;
        line-height: 1;
        letter-spacing: -3px;
      }
    }
    .text {
      color: #373A36;
      font-family: "Roboto", Sans-serif;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 50px;
      margin-top: -10px;
      text-align: left;
      .subtitle {
        color: #373A36;
        font-size: 20px;
        font-weight: 600;
      }
    }
    .options {
      width: 100%;
      text-align: left;
      .name {
        color: #595959;
      }
      .content {
        margin: 10px 0;

        .option {
          width: 31px;
          height: 27px;
          display: inline-block;
        }
      }
    }
    .button {
      margin-bottom: 50px;
      .more {
        @include button();
      }
    }
  }
  .spec {
    width: 16.33%;
    padding: 20px 0;
    box-sizing: border-box;
    color: #595959;
    text-align: left;
    li {
      list-style-type: none;
      margin: 15px 0;

      .icon-item {
        font-size: 22px;
        margin-right: 4px;
        vertical-align: -5px;
      }
    }
  }
  .image {
    width: 50.002%;
    padding: 10px 0;
    position: relative;
    img {
      max-width: 300px;
      max-height: 85%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
  }
}
@media (max-width: 768px) {
  .item-card {
    margin-top: 0;
    margin-bottom: 20px;
  }
  .container {
    max-width: 100%;
    padding: 20px;
    margin: auto;
    display: block;
    .caption, .spec, .image {
      width: 100%;
      display: block;
      margin: 0;
    }
    .caption {
      .button {
        margin-bottom: 20px;
      }
    }
    .image {
      min-height: 250px;
    }
  }
}
</style>