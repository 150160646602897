<script setup>
import { ref } from 'vue'
import { http } from '../lib.js'
import Banner from "./components/Banner.vue";
import Footer from "./components/Footer.vue";
const form = ref({
  username: '',
  email: '',
  phone: '',
  message: ''
})
const feedback = ref({
  title: 'Your request had been received, we will contact you later.',
  show: false
})
const send = () => {
  let val = form.value;
  let data = {
    username: val.username.trim(),
    email: val.email.trim(),
    phone: val.phone.trim(),
    message: val.message.trim()
  }
  if (!data.username || !data.email || !data.phone || !data.message) {
    return;
  }

  http.post('message', data, res => {
    if (res.data) {
      form.value = {
        username: '',
        email: '',
        phone: '',
        message: ''
      };
      feedback.value.show = true;
    }
  })
}
</script>

<template>
  <Banner />
  <div class="content">
    <div class="wrapper">
      <div class="contact-container">
        <div class="contact-form">
          <div class="title">Contact us</div>
          <div class="form">
            <div class="form-item">
              <label name="username">USERNAME</label>
              <input v-model="form.username" placeholder="Username" />
            </div>
            <div class="form-item">
              <label name="email">EMAIL</label>
              <input v-model="form.email" placeholder="Your Email" />
            </div>
            <div class="form-item">
              <label name="phone">PHONE NUMBER</label>
              <input v-model="form.phone" placeholder="Number" />
            </div>
            <div class="form-item">
              <label name="username">YOUR REQUEST</label>
              <input v-model="form.message" placeholder="Message" />
            </div>
            <div class="submit" @click="send">SEND MESSAGE</div>
          </div>
          <el-alert
              v-if="feedback.show"
              :title="feedback.title"
              type="success"
              :closable="true"
              class="feedback">
          </el-alert>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<style lang="scss" scoped>
@import '@/assets/scss/common.scss';
.content {
  overflow: hidden;
  background-color: transparent;
  background-image: linear-gradient(160deg, #376CAF 16%, #f2295b 100%);
  font-font: "Open Sans", sans-serif;

  .wrapper {
    max-width: 90%;
    margin: auto;

    .contact-container {
      max-width: 800px;
      background: #FFFFFF;
      margin: 20px auto;
      border: 1px solid #bfbfbf;
      box-shadow: 0px 0px 19px 11px rgb(0 0 0 / 50%);
    }
  }
}

.contact-form {
  display: flex;
  padding: 50px 150px;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;

  .title {
    color: #000000;
    text-align: center;
    margin-bottom: 70px;
    width: 100%;
    font-size: 55px;
    font-weight: 600;
  }
  .form {
    width: 100%;

    .form-item {
      text-align: left;
      margin-bottom: 15px;
      label {
        padding-bottom: 5px;
        margin-bottom: 3px;
        font-size: 15px;
        font-weight: 600;
        letter-spacing: 1px;
        color: #bfbfbf;
        display: block;
      }
      input {
        background-color: rgba(255,255,255,0);
        border-color: #686868;
        border-radius: 0;
        border: 1px solid #818a91;
        font-size: 16px;
        font-weight: 300;
        min-height: 40px;
        line-height: 1.8;
        padding: 6px 12px;
        display: inline-block;
        width: 100%;
        &:focus {
          outline: none;
        }
        &::-webkit-input-placeholder, &::-ms-input-placeholder {
          color: #666666;
        }
      }
    }

    .submit {
      @include button();
      width: 100%;
      border-radius: 0;
      border-width: 1px;
      outline: none;
      &:focus {
        border-width: 1px;
      }
    }
  }

  .feedback {
    margin-top: 20px;
  }
}


@media (max-width: 767px) {
  .contact-form {
    padding: 50px 25px;
  }
}
</style>