<script>
import { http, constants } from '../../lib.js'
import { ElMessage, ElMessageBox } from 'element-plus'
export default {
  data() {
    return {
      constants: constants,
      modalTitle: '新增分类',
      actionType: 'create',
      showCreateEditWin: false,
      form: {
        name: '',
        path: '',
        image_id: '',
        image_name: '',
        description: '',
        pretty_name: ''
      },
      fileList: [],
      tableData: []
    }
  },
  methods: {
    uploadSuccess(data) {
      this.form.image_id = data.image_id;
      this.form.image_name = data.name;
    },
    removeFile(data) {
      console.log(data)
    },
    create() {
      let form = this.form;
      if (form.name.trim() === '' || form.image_id === '') {
        ElMessage({
          type: 'info',
          message: '请填写分类名称及图片',
        })
        return;
      };
      http.post('category', this.form, res => {
        this.tableData.push(res.data);
        this.showCreateEditWin = false;
      })
    },
    update() {
      http.put('category', this.form, res => {
        if (res.data) {
          this.showCreateEditWin = false;
          ElMessage({
            type: 'info',
            message: '更新成功',
          })
        }
      })
    },
    action() {
      if (this.actionType === 'create') this.create();
      if (this.actionType === 'update') this.update();
    },
    showCreate() {
      this.modalTitle = '新增分类';
      this.actionType = 'create';
      this.fileList = [];
      this.form = {};
      this.showCreateEditWin = true;
    },
    showEdit(scope) {
      this.modalTitle = '编辑分类';
      this.actionType = 'update';
      this.fileList = []
      this.form = scope.row;
      this.fileList.push({
        name: this.form.pretty_name,
        url: constants.image_root + this.form.image_name
      })
      this.showCreateEditWin = true;
    },
    showDelete(scope) {
      ElMessageBox.confirm('确定要删除分类吗？', '删除分类', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        callback: (action) => {
          if (action == 'confirm') {
            let id = scope.row.id;
            http.delete('category', { id: id }, res => {
              let idx = this.getIndex(this.tableData, 'id', id);
              if (res.data) {
                this.tableData.splice(idx, 1)
                ElMessage({
                  type: 'info',
                  message: '删除成功',
                })
              }
            })
          }
        },
      })
    },
    getIndex(data, fieldName, fieldValue) {
      let idx = -1;
      data.forEach((value, index) => {
        if (value[fieldName] === fieldValue) {
          idx = index;
        }
      })
      return idx;
    }
  },
  created() {
    http.get('category', null, res => {
      this.tableData = [].concat(this.tableData, res.data);
    })
  }
}
</script>

<template>
  <el-row>
    <div class="top-operation">
      <el-button type="success" @click="showCreate">新增分类</el-button>
    </div>
  </el-row>
  <el-row>
    <el-table :data="tableData" class="table-content">
      <el-table-column prop="name" label="名称"></el-table-column>
      <el-table-column prop="picture" label="图片" >
        <template #default="scope">
          <p><img class="uploaded-images" :src="constants.image_root + scope.row.image_name"/></p>
        </template>
      </el-table-column>
      <el-table-column prop="description" label="描述"></el-table-column>
      <el-table-column label="操作">
        <template #default="scope">
          <el-button type="primary" @click="showEdit(scope)">编辑</el-button>
          <el-button type="danger" @click="showDelete(scope)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-row>
  <el-dialog
      v-model="showCreateEditWin"
      :title="modalTitle"
      width="40%"
  >
    <el-row>
      <el-col :span="22" :offset="1">
        <el-form :model="form" label-width="80px" size="large">
          <el-form-item label="分类名称">
            <el-input v-model="form.name" />
          </el-form-item>
          <el-form-item label="菜单路径">
            <el-input v-model="form.path" />
          </el-form-item>
          <el-form-item label="分类图片">
            <el-upload
                class="upload"
                :action="constants.upload_url"
                :on-success="uploadSuccess"
                :on-remove="removeFile"
                :file-list="fileList"
                list-type="picture-card"
                :limit="1"
            >
              <ion-icon name="add-outline" style="font-size: 28px;"></ion-icon>
            </el-upload>
          </el-form-item>
          <el-form-item label="描述">
            <el-input v-model="form.description" type="textarea" :rows="8"/>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="showCreateEditWin = false" size="large">取消</el-button>
        <el-button type="primary" @click="action" size="large">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<style lang="scss" scoped>
.top-operation {
  margin-bottom: 20px;
  text-align: right;
  width: 100%;
}
.uploaded-images {
  width: 148px;
  max-height: 148px;
  margin-right: 8px;
  border-radius: 4px;
}
</style>