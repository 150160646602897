<script setup>
import { ref, computed } from 'vue';
import { http, constants } from '../../lib';
import { useRouter } from 'vue-router';

const router = useRouter();
const menus = ref([
  {
    name: 'HOME',
    path: '/'
  }, {
    name: 'PRODUCTS',
    path: '/product',
    children: []
  },{
    name: 'ABOUT',
    path: '/about'
  }, {
    name: 'CONTACT',
    path: '/contact'
  }
])
const showSubMenu = ref(false)
const showMobileMenu = ref(false);
const showMenu = (name) => {
  if (name === true || name === 'PRODUCTS') {
    showSubMenu.value = true;
  }
}
const hideMenu = () => {
  showSubMenu.value = false;
}
const navigateRootPath = (path) => {
  if (path.indexOf('http') === -1) {
    router.push({ path: path });
  } else {
    window.open(path);
  }
}
const toggleMobileMenu = () => {
  showMobileMenu.value = !showMobileMenu.value;
}
http.get('category', null, res => {
  let category = res.data;
  menus.value[1].children = category;
})
</script>

<template>
<div id="banner">
  <div class="wrapper">
    <div class="logo">
      <a href="#">
        <img alt="SuperblueTech Logo" src="../../assets/images/header-logo.png" />
      </a>
    </div>
    <div class="menu">
      <ul>
        <li class="menu-item" v-for="menu in menus">
          <a :href="menu.path"
             @click.prevent="navigateRootPath(menu.path)"
             @mouseover="showMenu(menu.name)"
             @mouseout="hideMenu()"
             >{{ menu.name }}</a>
        </li>
      </ul>
    </div>
    <div class="mobile-menu">
      <div class="icon" @click="toggleMobileMenu" :class="{ active: showMobileMenu }">
        <ion-icon name="menu-outline"></ion-icon>
      </div>
      <ul class="mobile-menu-list" :style="{ height: showMobileMenu ? 'auto' : 0 }">
        <li class="menu-item" v-for="menu in menus">
          <a :href="menu.path"
             @click.prevent="navigateRootPath(menu.path)"
          >{{ menu.name }}</a>
        </li>
      </ul>
    </div>
  </div>
  <div class="dropdown"
       :class="{ 'show-sub-menu': showSubMenu }"
       @mouseover="showMenu(true)"
       @mouseout="hideMenu()">
    <router-link
        class="dropdown-item"
        v-for="menu in menus[1].children"
        :to="'/product?category=' + menu.path">
      <img :src="constants.image_root + menu.image_name">
      <span>{{ menu.name }}</span>
    </router-link>
  </div>
</div>
</template>

<style lang="scss" scoped>

#banner {
  background-color: #FFFFFF;
  border-bottom: 1px solid #f1f1f1;

  .dropdown {
    position: absolute;
    z-index: 10;
    top: 74px;
    left: 0;
    width: 100%;
    height: 0;
    padding-left: 145px;
    overflow: hidden;
    background-color: #F6F6F9;
    transition: 0.5s 0s all ease;
    &.show-sub-menu {
      height: 160px;
    }
    .dropdown-item {
      margin-right: 40px;
      color: #555555;
      letter-spacing: 0.6px;
      display: inline-block;
      text-decoration: none;
      font-size: 13px;
      padding: 20px 15px;
      line-height: 74px;
      cursor: pointer;
      transition: all 0.3s ease;
      &:hover {
        color: #5FAFF1;
      }
      img {
        height: 100px;
        vertical-align: middle;
      }
      span {
        display: block;
        text-align: center;
        font-size: 0.875rem;
        line-height: 2rem;
      }
    }
  }
  .wrapper {
    height: 100%;
    max-width: 1200px;
    margin: auto;
    padding: 0 20px;
    overflow: hidden;

    .logo {
      height: 74px;
      float: left;

      img {
        height: 40px;
        margin-top: 17px;
      }
    }
    .menu {
      float: right;
      text-align: right;
      position: relative;
      right: -15px;

      .menu-item {
        position: relative;
        display: inline-block;
        list-style-type: none;
        float: left;
        a {
          color: #555555;
          letter-spacing: 0.6px;
          display: block;
          text-decoration: none;
          font-size: 13px;
          padding: 0 15px;
          line-height: 74px;
          cursor: pointer;
          transition: all 0.3s ease;
          &:hover {
            color: #5FAFF1;
          }
        }
      }
    }
    .mobile-menu {
      display: none;
      .icon {
        display: inline-block;
        padding: 15px;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        transition: transform .3s;
        &.active {
          transform: rotate(-90deg);
        }
      }
      .mobile-menu-list {
        overflow: hidden;
        position: absolute;
        top: 50px;
        left: 0;
        right: 0;
        background: #FFFFFF;
        z-index: 10;
        box-shadow: 0 15px 15px 0 rgba(0, 0, 0,.21);

        .menu-item {
          display: block;
          list-style-type: none;
          letter-spacing: 0.6px;
          font-size: 13px;
          padding: 0 15px;
          line-height: 45px;
          cursor: pointer;
          transition: all 0.3s ease;
          a {
            text-decoration: none;
            color: #555555;
          }
        }
      }
    }
  }
}
@media (max-width: 576px) {
  #banner {
    .wrapper {
      .logo {
        height: 50px;
        img {
          margin-top: 5px;
        }
      }
      .menu {
        display: none;
      }
      .mobile-menu {
        display: block;
      }
    }
  }
}
</style>
