<script>
import { http, utility } from '../../lib'
import { ElMessage, ElMessageBox } from 'element-plus'
export default {
  data() {
    return {
      tableData: [],
    }
  },
  methods: {
    showDelete(scope) {
      ElMessageBox.confirm('确定要删除留言吗？', '删除留言', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        callback: (action) => {
          if (action == 'confirm') {
            let id = scope.row.id;
            http.delete('message', {id: id}, res => {
              let idx = utility.getIndex(this.tableData, 'id', id);
              if (res.data) {
                this.tableData.splice(idx, 1)
                ElMessage({
                  type: 'info',
                  message: '删除成功',
                })
              }
            })
          }
        },
      });
    }
  },
  created() {
    http.get('message', null, res => {
      this.tableData = [].concat(this.tableData, res.data);
    })
  }
}
</script>

<template>
  <el-row>
    <el-table :data="tableData" class="table-content">
      <el-table-column prop="username" label="用户名称" min-width="180px"></el-table-column>
      <el-table-column prop="email" label="邮箱" min-width="200px"></el-table-column>
      <el-table-column prop="phone" label="电话" min-width="150px"></el-table-column>
      <el-table-column prop="message" label="留言请求" min-width="300px"></el-table-column>
      <el-table-column label="操作" min-width="120px">
        <template #default="scope">
          <el-button type="danger" @click="showDelete(scope)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-row>
</template>

<style lang="scss" scoped>
</style>