import * as axios from 'axios'
const env = import.meta.env
const prod = env.MODE === 'production' // 是否为生产环境
const protocol = prod ? 'https://' : 'http://'
const domain = prod ? 'www.superbluetech.com' : 'www.superblue.com'
const host = protocol + domain
const apiPrefix = prod ? '' : '/superblue/'
const path = host + apiPrefix + '/api/'

export const http = {
    get(url, data, callback) {
        return axios.get(path + url, {
            params: data
        }).then(res => {
            callback && callback(res);
            return res;
        })
    },
    post(url, data, callback) {
        return axios.post(path + url, data, {
        }).then(res => {
            callback && callback(res);
            return res;
        })
    },
    put(url, data, callback) {
        return axios.put(path + url, data).then(res => {
            callback && callback(res);
            return res;
        })
    },
    delete(url, data, callback) {
        return axios.delete(path + url, {
            data: data
        }).then(res => {
            callback && callback(res);
            return res;
        })
    }
}
export const utility = {
    getIndex(data, fieldName, fieldValue) {
        let idx = -1;
        data.forEach((value, index) => {
            if (value[fieldName] === fieldValue) {
                idx = index;
            }
        })
        return idx;
    },
    parseQuery(str) {
        let idx = str.indexOf('?');
        if (idx === -1) return {}

        let query = {}
        let q = str.substr(idx + 1)
        q.split('&').forEach(v => {
            let pair = v.split('=')
            query[pair[0]] = pair[1]
        })
        return query;
    },
    format: {
        align: {
            text(v) {
                const preset = ['左侧', '中间', '右侧']
                return preset[v];
            },
            style(v) {
                const preset = ['start', 'center', 'end']
                return preset[v];
            }
        }
    }
}
export const constants = {
    image_root: prod ? host + '/upload/' : `${host}:81/upload/`,
    upload_url: path + 'upload',
    prod: prod
}