<script>
import { http, constants } from '../../lib.js'
import Spec from "../components/Spec.vue";
import Card from "../components/Card.vue";
import Sku from "../components/Sku.vue";
import { ElMessage, ElMessageBox } from 'element-plus'

export default {
  components: {
    Spec,
    Card,
    Sku
  },
  data() {
    return {
      constants: constants,
      showCreateEditWin: false, // 产品新增编辑窗口
      showCardsWin: false, // 卡片新增编辑窗口
      showSkusWin: false, // SKU 颜色新增编辑窗口
      modalTitle: '新增产品',
      actionType: 'create',
      form: {
        id: 0,
        name: '',
        caption: '',
        category: 0,
        skus: [],
        cards: [],
        specs: [],
        description: '',
        tech_spec: '',
        box_content: ''

      },
      fileList: [],
      categories: [],
      tableData: [],
    }
  },
  methods: {
    getCategoryName(id) {
      let name = '';
      this.categories.forEach(v => {
        if (v.id === id) name = v.name;
      })
      return name;
    },
    createOrEdit() {
      let spec = [];
      this.form.specs.forEach(value => {
        spec.push(value.name + '##' + value.text)
      })
      let data = {
        id: this.form.id,
        name: this.form.name,
        caption: this.form.caption,
        description: this.form.description,
        category_id: this.form.category,
        spec: spec.join('@@'),
        image: this.form.image,
        tech_spec: this.form.tech_spec,
        box_content: this.form.box_content
      }

      if (this.actionType === 'create') {
        http.post('product', data, res => {
          if (res.data) {
            let item = Object.assign({}, this.form)
            item.id = res.data.id;
            item.category_name = this.getCategoryName(this.form.category);
            this.tableData.push(item);
            this.showCreateEditWin = false;
          }
        })
      }
      if (this.actionType === 'update') {
        http.put('product', data, res => {
          if (res.data) {
            this.tableData[this.form.index].name = this.form.name
            this.tableData[this.form.index].caption = this.form.caption
            this.tableData[this.form.index].description = this.form.description
            this.tableData[this.form.index].category_id = this.form.category_id
            this.tableData[this.form.index].image = this.form.image,
            this.tableData[this.form.index].image_name = this.form.image_name
            this.tableData[this.form.index].spec = this.form.spec
            this.tableData[this.form.index].tech_spec = this.form.tech_spec
            this.tableData[this.form.index].box_content = this.form.box_content
            this.showCreateEditWin = false;
          }
        })
      }
    },
    showCreateEdit(type, scope) {
      if (type === 'create') {
        this.modalTitle = '新增产品'
        this.actionType = 'create'
        this.form = {
          specs: []
        }
        this.fileList = []
      }
      if (type === 'update') {
        this.modalTitle = '编辑产品'
        this.actionType = 'update'
        this.fileList = [{
          url: constants.image_root + scope.row.image_name
        }]
        this.form = Object.assign({ index: scope.$index }, scope.row);
      }
      this.showCreateEditWin = true;
    },
    specChange(type, data) {
      let index = data.index
      let name = data.name
      if (type === 'delete') {
        this.form.specs.splice(index, 1)
      }
      if (type === 'create') {
        this.form.specs.push({
          name: name,
          text: ''
        })
      }
      if (type === 'update') {
        this.form.specs[index].name = name
      }
      if (type === 'moveUp') {
        if (index === 0) return;
        let old = this.form.specs.splice(index, 1);
        this.form.specs.splice(index - 1, 0, old[0]);
      }
      if (type === 'moveDown') {
        if (index === (this.form.specs.length - 1)) return;
        let old = this.form.specs.splice(index, 1);
        this.form.specs.splice(index + 1, 0, old[0]);
      }
    },
    cardChange(type, data) {
      let index = data.index
      let id = data.id
      let caption = data.caption
      let description = data.description
      let image_id = data.image_id
      let order_no = data.order_no
      if (type === 'delete') {
        ElMessageBox.confirm('确定要删除卡片吗？', '删除产品', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          callback: (action) => {
            if (action == 'confirm') {
              http.delete('card', { id: id }, res => {
                if (res.data) {
                  this.form.cards.splice(index, 1)
                }
              })
            }
          },
        })
      }
      if (type === 'create') {
        http.post('card', data, res => {
          if (res.data) {
            this.form.cards.push({
              id: res.data.id,
              caption: data.caption,
              description: data.description,
              image_id: data.image_id,
              image_name: data.image_name,
              product_id: data.product_id,
              order_no: data.order_no
            })
          }
        })

      }
      if (type === 'update') {
        this.form.cards[index].caption = caption
        this.form.cards[index].description = description
        this.form.cards[index].image_id = image_id
      }
      if (type === 'moveUp') {
        if (index === 0) return;

        // 先复制一份，获取重排后的顺序
        let clone = Array.from(this.form.cards)
        let oldClone = clone.splice(index, 1);
        clone.splice(index - 1, 0, oldClone[0])

        let postData = clone.map((v, i) => {
          let card = Object.assign({}, v);
          card.order_no = i + 1;
          return card;
        })
        http.put('card', postData, res => {
          if (res.data) {
            let old = this.form.cards.splice(index, 1);
            this.form.cards.splice(index - 1, 0, old[0]);
          }
        })
      }
      if (type === 'moveDown') {
        if (index === (this.form.cards.length - 1)) return;

        // 先复制一份，获取重排后的顺序
        let clone = Array.from(this.form.cards)
        let oldClone = clone.splice(index, 1);
        clone.splice(index + 1, 0, oldClone[0])

        let postData = clone.map((v, i) => {
          let card = Object.assign({}, v);
          card.order_no = i + 1;
          return card;
        })
        http.put('card', postData, res => {
          if (res.data) {
            let old = this.form.cards.splice(index, 1);
            this.form.cards.splice(index + 1, 0, old[0]);
          }
        })
      }
    },
    skuChange(type, data) {
      let index = data.index
      let id = data.id
      let name = data.name
      let images = data.images
      let color = data.color
      let order_no = data.order_no

      if (type === 'delete') {
        ElMessageBox.confirm('确定要删除颜色吗？', '删除产品', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          callback: (action) => {
            if (action == 'confirm') {
              http.delete('sku', { id: id }, res => {
                if (res.data) {
                  this.form.skus.splice(index, 1)
                }
              })
            }
          },
        })
      }
      if (type === 'create') {
        let imageArray = [];
        Object.keys(images).forEach(uid => {
          imageArray.push(images[uid])
        })
        let imageStr = imageArray.sort((a, b) => {
          return a.image_id > b.image_id
        }).map(v => v.image_id).join(',');
        let formData = {
          name: name,
          product_id: data.product_id,
          images: imageStr,
          color: color,
          order_no: order_no
        }
        http.post('sku', formData, res => {
          if (res.data) {
            this.form.skus.push({
              id: res.data.id,
              name: data.name,
              images: imageArray.sort((a, b) => {
                return a.image_id > b.image_id
              }),
              color: color,
              product_id: data.product_id,
              order_no: data.order_no
            })
          }
        })
      }
      if (type === 'update') {
        let imageArray = [];
        Object.keys(images).forEach(uid => {
          imageArray.push(images[uid])
        })
        let imageStr = imageArray.sort((a, b) => {
          return a.image_id > b.image_id
        }).map(v => v.image_id).join(',');
        let postData = {
          id: id,
          name: name,
          product_id: data.product_id,
          images: imageStr,
          color: color,
          order_no: order_no
        }
        http.put('sku', [postData], res => {
          if (res.data) {
            this.form.skus[index].name = name
            this.form.skus[index].images = imageArray.sort((a, b) => {
              return a.image_id > b.image_id
            })
            this.form.skus[index].color = color
          }
        })
      }
      if (type === 'moveUp') {
        if (index === 0) return;

        // 先复制一份，获取重排后的顺序
        let clone = Array.from(this.form.skus)
        let oldClone = clone.splice(index, 1);
        clone.splice(index - 1, 0, oldClone[0])

        let postData = clone.map((v, i) => {
          let sku = Object.assign({}, v);
          sku.order_no = i + 1;
          sku.images = v.images.map(s => s.id).join(',')
          return sku;
        })
        http.put('sku', postData, res => {
          if (res.data) {
            let old = this.form.skus.splice(index, 1);
            this.form.skus.splice(index - 1, 0, old[0]);
          }
        })
      }
      if (type === 'moveDown') {
        if (index === (this.form.skus.length - 1)) return;

        // 先复制一份，获取重排后的顺序
        let clone = Array.from(this.form.skus)
        let oldClone = clone.splice(index, 1);
        clone.splice(index + 1, 0, oldClone[0])

        let postData = clone.map((v, i) => {
          let sku = Object.assign({}, v);
          sku.order_no = i + 1;
          sku.images = v.images.map(s => s.id).join(',')
          return sku;
        })
        http.put('sku', postData, res => {
          if (res.data) {
            let old = this.form.skus.splice(index, 1);
            this.form.skus.splice(index + 1, 0, old[0]);
          }
        })
      }
    },
    deleteProduct(scope) {
      let index = scope.$index;
      let id = scope.row.id;

      ElMessageBox.confirm('确定要删除产品吗？', '删除产品', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        callback: (action) => {
          if (action == 'confirm') {
            let id = scope.row.id;
            http.delete('product', { id: id }, res => {
              if (res.data) {
                this.tableData.splice(index, 1)
                ElMessage({
                  type: 'info',
                  message: '删除成功',
                })
              }
            })
          }
        },
      })
      http.delete('product')
    },
    manageCards(scope) {
      let data = scope.row;
      this.form.id = data.id;
      http.get('card', { product_id: data.id }, res => {
        if (res.data && res.data.length) {
          this.form.cards = res.data;
          this.showCardsWin = true;
        } else {
          this.form.cards = [];
          this.showCardsWin = true;
        }
      })
    },
    manageSkus(scope) {
      let data = scope.row;
      this.form.id = data.id;
      this.form.skus = [];
      http.get('sku', { product_id: data.id }, res => {
        if (res.data && res.data.length) {
          res.data.forEach(sku => {
            this.form.skus.push({
              id: sku.id,
              name: sku.name,
              image_str: sku.images,
              color: sku.color,
              images: [],
              product_id: sku.product_id,
              order_no: sku.order_no
            })
          })

          // 分两步解决异步调用顺序乱掉的问题
          this.form.skus.forEach(sku => {
            http.get('image', { image_str: sku.image_str }, res => {
              sku.images = res.data;
            })
          })

          this.showSkusWin = true;
        } else {
          this.form.skus = [];
          this.showSkusWin = true;
        }
      })
    },
    uploadSuccess(data) {
      this.form.image = data.image_id;
      this.form.image_name = data.name;
    }
  },
  created() {
    http.get('category', null, res => {
      this.categories = res.data;
    })
    http.get('product', null, res => {
      res.data.forEach(product => {
        let spec = []
        let specs = product.spec.split('@@')
        specs.forEach(s => {
          let part = s.split('##');
          spec.push({
            name: part[0],
            text: part[1]
          })
        })
        this.tableData.push({
          id: product.id,
          name: product.name,
          caption: product.caption,
          description: product.description,
          category: product.category_id,
          category_name: product.category_name,
          specs: spec,
          image: product.image,
          image_name: product.image_name,
          tech_spec: product.tech_spec,
          box_content: product.box_content,
          cards: product.cards,
          skus: product.skus
        })
      })
    })
  }
}
</script>

<template>
  <el-row>
    <div class="top-operation">
      <el-button type="success" @click="showCreateEdit('create')">新增产品</el-button>
    </div>
  </el-row>
<!--  产品表格-->
  <el-row>
    <el-table :data="tableData" class="table-content">
      <el-table-column prop="name" label="名称" min-width="160px"></el-table-column>
      <el-table-column prop="image" label="主图" min-width="160px">
        <template #default="scope">
          <img :src="constants.image_root + scope.row.image_name" style="width: 140px"/>
        </template>
      </el-table-column>
      <el-table-column prop="picture" label="颜色" min-width="100px">
        <template #default="scope">
          <el-button type="default" size="small" @click="manageSkus(scope)">管理</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="category_name" label="分类" min-width="160px"></el-table-column>
      <el-table-column prop="spec" label="规格" min-width="100px">
        <template #default="scope">
          <el-popover
              placement="right"
              :width="200"
              trigger="hover"
          >
            <template #reference>
              <el-icon :size="20" style="cursor: pointer">
                <InfoFilled/>
              </el-icon>
            </template>
            <p v-for="(spec) in scope.row.specs" class="spec-preview">
              <ion-icon :name="spec.name"></ion-icon>
              <span>{{ spec.text}}</span>
            </p>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="cards" label="描述卡片" min-width="120px">
        <template #default="scope">
          <el-button type="default" size="small" @click="manageCards(scope)">管理</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="description" label="描述" min-width="280px"></el-table-column>
      <el-table-column label="操作" min-width="160px">
        <template #default="scope">
          <el-button type="primary" @click="showCreateEdit('update', scope)">编辑</el-button>
          <el-button type="danger" @click="deleteProduct(scope)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-row>
  <!--  SKU(颜色)管理-->
  <el-dialog
      v-model="showSkusWin"
      title="管理颜色"
  >
    <el-row :gutter="20" style="margin-bottom: 35px; text-align: left; font-weight: bold;">
      <el-col :span="5">名称</el-col>
      <el-col :span="2">颜色</el-col>
      <el-col :span="12">图片</el-col>
      <el-col :span="5">操作</el-col>
    </el-row>
    <Sku :skus="form.skus" :id="form.id" @change="skuChange"></Sku>
  </el-dialog>
<!--  卡片管理-->
  <el-dialog
      v-model="showCardsWin"
      title="管理卡片">
    <el-row :gutter="20" style="margin-bottom: 35px; text-align: left; font-weight: bold;">
      <el-col :span="5">标题</el-col>
      <el-col :span="5">图片</el-col>
      <el-col :span="10">描述</el-col>
      <el-col :span="4">操作</el-col>
    </el-row>
    <Card :cards="form.cards" :id="form.id" @change="cardChange"></Card>
  </el-dialog>
<!--  产品管理-->
  <el-dialog
      v-model="showCreateEditWin"
      :title="modalTitle"
  >
    <el-row>
      <el-col :span="24">
        <el-form :model="form" size="large" label-width="80px">
          <el-form-item label="产品名称">
            <el-input v-model="form.name" />
          </el-form-item>
          <el-form-item label="副标题">
            <el-input v-model="form.caption" />
          </el-form-item>
          <el-form-item label="主图">
            <el-upload
                class="upload"
                :action="constants.upload_url"
                :on-success="uploadSuccess"
                list-type="picture-card"
                :file-list="fileList"
                :multiple="false"
                :limit="1"
            >
              <ion-icon name="add-outline" style="font-size: 28px;"></ion-icon>
            </el-upload>
          </el-form-item>
          <el-form-item label="产品分类">
            <el-select v-model="form.category" class="m-2" placeholder="Select" size="large">
              <el-option
                  v-for="item in categories"
                  :key="item.name"
                  :label="item.name"
                  :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="规格">
            <Spec :specs="form.specs" @change="specChange"></Spec>
          </el-form-item>
          <el-form-item label="描述">
            <el-input v-model="form.description" type="textarea" :rows="6"/>
          </el-form-item>
          <el-form-item label="规格列表">
            <el-input v-model="form.tech_spec" type="textarea" :rows="6"/>
          </el-form-item>
          <el-form-item label="包装内容">
            <el-input v-model="form.box_content" type="textarea" :rows="6"/>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="showCreateEditWin = false">取消</el-button>
        <el-button type="primary" @click="createOrEdit">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<style lang="scss" scoped>
.top-operation {
  margin-bottom: 20px;
  text-align: right;
  width: 100%;
}
.spec-preview {
  line-height: 1.8;
  ion-icon {
    margin-right: 10px;
    font-size: 1.4em;
    vertical-align: -4px;
  }
}
</style>