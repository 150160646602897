<script>
import { http } from '../lib.js'
import { useRouter } from 'vue-router'
export default {
  data() {
    return {
      router: null,
      form: {
        username: '',
        password: ''
      }
    }
  },
  methods: {
    login() {
      http.post('authenticate', {
        username: this.form.username,
        password: this.form.password
      }, res => {
        if (res.data) {
          // 登录成功
          this.router.push({ path: '/dashboard/product' })
        }
      })
    },
    register() {}
  },
  mounted() {
    this.router = useRouter();
  }
}
</script>

<template>
<div id="auth">
  <div class="login-form">
    <el-form :model="form" label-width="80px" size="large" label-position="left">
      <el-form-item label="用户名">
        <el-input v-model="form.username" />
      </el-form-item>
      <el-form-item label="密码">
        <el-input v-model="form.password" type="password" />
      </el-form-item>
      <el-form-item>
        <el-button type="default" size="large" @click="register">注册</el-button>
        <el-button type="primary" size="large" @click="login">登陆</el-button>
      </el-form-item>
    </el-form>
  </div>
</div>
</template>

<style lang="scss" scoped>
#auth {
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  padding-top: 200px;
}
.login-form {
  width: 480px;
  margin: auto;
  padding: 40px 0;
}
</style>