<script setup>
import { ref } from 'vue';
import { constants } from "../../lib";
import { ElMessage, ElMessageBox } from 'element-plus'
const props = defineProps({
  skus: {
    type: Array
  },
  id: {
    type: Number
  }
})
const newSku = ref({
  name: '',
  color: '',
  images: {}
})
const fileList = ref([]);
const emit = defineEmits(['change'])
const showEditModal = ref(false)
const editData = ref({});
const editDataFileList = ref([]);
const createSku = () => {
  let data = newSku.value;
  if (!data.name || data.images.length < 1) {
    ElMessage('请填写名称并上传图片')
    return;
  }
  newSku.value.product_id = props.id;
  if (props.skus.length > 0) {
    newSku.value.order_no = props.skus[props.skus.length - 1].order_no + 1
  } else {
    newSku.value.order_no = 1
  }
  emit('change', 'create', newSku.value)
  newSku.value = {
    name: '',
    images: {}
  }
  fileList.value = [];
}
const uploadSuccess = function(data) {
  fileList.value.forEach(v => {
    newSku.value.images[v.uid] = v.response;
  })
}
const editDataUploadSuccess = function(data) {
  editDataFileList.value.forEach(v => {
    if (!v.response) return;
    editData.value.images[v.uid] = v.response;
  })
}
const removeImage = (data) => {
  delete newSku.value.images[data.uid];
}
const editDataRemoveImage = (data) => {
  if (data.id) {
    delete editData.value.images[data.id]
  } else {
    delete editData.value.images[data.uid];
  }
}
const moveUp = (sku, index) => {
  let data = Object.assign({ index: index}, sku)
  emit('change', 'moveUp', data)
}
const moveDown = (sku, index) => {
  let data = Object.assign({ index: index}, sku)
  emit('change', 'moveDown', data)
}
const deleteSku = (sku, index) => {
  let data = Object.assign({ index: index}, sku)
  emit('change', 'delete', data)
}
let editIndex = -1;
const editSku = (sku, index) => {
  editIndex = index;
  editData.value = Object.assign({}, sku);
  if(!editData.value.color) {
    editData.value.color = 'rgb(0, 0, 0)';
  }
  editDataFileList.value = []
  editData.value.images = {}
  if (sku.images) {
    sku.images.forEach(image => {
      editDataFileList.value.push({
        name: image.pretty_name,
        id: image.id,
        url: constants.image_root + image.name
      })
      editData.value.images[image.id] = image;
      editData.value.images[image.id].image_id = image.id;
    })
  }
  showEditModal.value = true;
}
const saveEditContent = () => {
  let data = Object.assign({ index: editIndex }, editData.value)
  emit('change', 'update', data);
  showEditModal.value = false;
}
</script>

<template>
  <div class="sku-container">
    <el-row v-for="(sku, index) in skus" :gutter="20" class="row">
      <el-col :span="5">
        <el-form-item>
          <p>{{ sku.name }}</p>
        </el-form-item>
      </el-col>
      <el-col :span="2">
        <el-form-item>
          <p>
            <span style="width: 50px; height: 24px; display: inline-block" :style="{ background: sku.color }">
            </span>
          </p>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item>
          <template v-for="image in sku.images">
            <img class="uploaded-images" :src="constants.image_root + image.name"/>
          </template>
        </el-form-item>
      </el-col>
      <el-col :span="5">
        <div class="op">
          <div class="icons">
            <div class="icon">
              <ion-icon name="arrow-up" @click="moveUp(sku, index)"></ion-icon>
            </div>
            <div class="icon">
              <ion-icon name="arrow-down" @click="moveDown(sku, index)"></ion-icon>
            </div>
            <div class="icon">
              <ion-icon name="close" @click="deleteSku(sku, index)"></ion-icon>
            </div>
            <div class="icon">
              <ion-icon name="create-outline" @click="editSku(sku, index)"></ion-icon>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row class="row" :gutter="20">
      <el-col :span="5">
        <el-form-item>
          <el-input placeholder="输入名称" v-model="newSku.name"/>
        </el-form-item>
      </el-col>
      <el-col :span="2">
        <el-form-item>
          <color-picker v-model:pureColor="newSku.color"/>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item>
          <el-upload
              class="upload"
              :file-list="fileList"
              :action="constants.upload_url"
              list-type="picture-card"
              :on-success="uploadSuccess"
              :on-remove="removeImage"
              multiple
          >
            <ion-icon name="add-outline" style="font-size: 28px;"></ion-icon>
          </el-upload>
        </el-form-item>
      </el-col>
      <el-col :span="5">
        <div class="op">
          <el-button class="save-icon" @click="createSku">保存</el-button>
        </div>
      </el-col>
    </el-row>
  </div>
  <div class="edit-modal">
    <el-dialog
        v-model="showEditModal"
        title="编辑"
        width="50%"
        :append-to-body="true"
    >
      <el-row class="row" :gutter="20">
        <el-col :span="5">
          <el-form-item>
            <el-input placeholder="输入名称" v-model="editData.name"/>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item>
            <color-picker v-model:pureColor="editData.color"/>
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item>
            <el-upload
                class="upload"
                :file-list="editDataFileList"
                :action="constants.upload_url"
                list-type="picture-card"
                :on-success="editDataUploadSuccess"
                :on-remove="editDataRemoveImage"
                multiple
            >
              <ion-icon name="add-outline" style="font-size: 28px;"></ion-icon>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="showEditModal = false">取消</el-button>
        <el-button type="primary" @click="saveEditContent"
        >保存</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<style lang="scss" scoped>
.sku-container {
  width: 100%;
}
.row {
  margin-bottom: 5px;

  .save-icon {
    margin-top: 40px;
  }
  .selected-icon {
    display: inline-block;
    float: left;
    width: 48px;
    font-size: 24px;
    cursor: pointer;
    :hover {
      color: #5FAFF1;
    }

    ion-icon {
      vertical-align: -4px;
    }
  }
  .uploaded-images {
    width: 148px;
    max-height: 148px;
    margin-right: 8px;
    border-radius: 4px;
  }
  .text {
    text-align: left;
    display: inline-block;
    width: calc(100% - 192px);
    input {
      height: 48px;
      line-height: 48px;
      outline: none;
      border: 1px solid #EAEAEA;
      width: 100%;
      color: rgba(96, 98, 102);
      border-radius: 4px;
      box-sizing: border-box;
      padding: 0 15px;
    }
  }
  .op {
    display: inline-block;
    width: 144px;
    .icon {
      display: inline-block;
      float: left;
      width: 36px;
      font-size: 24px;
      cursor: pointer;
      transition: all .5s;
      border-radius: 4px;
      &:hover {
        background: #EFEFEF;
      }

      ion-icon {
        vertical-align: -4px;
      }
    }
  }

  .add-new {
    span {
      font-size: 18px;
      vertical-align: -5px;
      margin-right: 10px;
    }
    ion-icon {
      font-size: 32px;
      vertical-align: -15px;
      cursor: pointer;
    }
  }
  .preview {
    width: 150px;
    height: 150px;
    border: 1px solid black;
    display: inline-block;
  }
  :deep(.vc-color-wrap) {
    min-width: 50px;
  }
}
</style>