<script setup>
import { ref } from 'vue';
const props = defineProps({
  specs: {
    type: Array
  }
})
const emit = defineEmits(['change'])
let showSelectorWin = ref(false);
const icons = [
  'accessibility-outline',
  'accessibility-sharp',
  'accessibility',
  'add-circle-outline',
  'add-circle-sharp',
  'add-circle',
  'add-outline',
  'add-sharp',
  'add',
  'airplane-outline',
  'airplane-sharp',
  'airplane',
  'alarm-outline',
  'alarm-sharp',
  'alarm',
  'albums-outline',
  'albums-sharp',
  'albums',
  'alert-circle-outline',
  'alert-circle-sharp',
  'alert-circle',
  'alert-outline',
  'alert-sharp',
  'alert',
  'american-football-outline',
  'american-football-sharp',
  'american-football',
  'analytics-outline',
  'analytics-sharp',
  'analytics',
  'aperture-outline',
  'aperture-sharp',
  'aperture',
  'apps-outline',
  'apps-sharp',
  'apps',
  'archive-outline',
  'archive-sharp',
  'archive',
  'arrow-back-circle-outline',
  'arrow-back-circle-sharp',
  'arrow-back-circle',
  'arrow-back-outline',
  'arrow-back-sharp',
  'arrow-back',
  'arrow-down-circle-outline',
  'arrow-down-circle-sharp',
  'arrow-down-circle',
  'arrow-down-outline',
  'arrow-down-sharp',
  'arrow-down',
  'arrow-forward-circle-outline',
  'arrow-forward-circle-sharp',
  'arrow-forward-circle',
  'arrow-forward-outline',
  'arrow-forward-sharp',
  'arrow-forward',
  'arrow-redo-circle-outline',
  'arrow-redo-circle-sharp',
  'arrow-redo-circle',
  'arrow-redo-outline',
  'arrow-redo-sharp',
  'arrow-redo',
  'arrow-undo-circle-outline',
  'arrow-undo-circle-sharp',
  'arrow-undo-circle',
  'arrow-undo-outline',
  'arrow-undo-sharp',
  'arrow-undo',
  'arrow-up-circle-outline',
  'arrow-up-circle-sharp',
  'arrow-up-circle',
  'arrow-up-outline',
  'arrow-up-sharp',
  'arrow-up',
  'at-circle-outline',
  'at-circle-sharp',
  'at-circle',
  'at-outline',
  'at-sharp',
  'at',
  'attach-outline',
  'attach-sharp',
  'attach',
  'backspace-outline',
  'backspace-sharp',
  'backspace',
  'bag-add-outline',
  'bag-add-sharp',
  'bag-add',
  'bag-check-outline',
  'bag-check-sharp',
  'bag-check',
  'bag-handle-outline',
  'bag-handle-sharp',
  'bag-handle',
  'bag-outline',
  'bag-remove-outline',
  'bag-remove-sharp',
  'bag-remove',
  'bag-sharp',
  'bag',
  'balloon-outline',
  'balloon-sharp',
  'balloon',
  'ban-outline',
  'ban-sharp',
  'ban',
  'bandage-outline',
  'bandage-sharp',
  'bandage',
  'bar-chart-outline',
  'bar-chart-sharp',
  'bar-chart',
  'barbell-outline',
  'barbell-sharp',
  'barbell',
  'barcode-outline',
  'barcode-sharp',
  'barcode',
  'baseball-outline',
  'baseball-sharp',
  'baseball',
  'basket-outline',
  'basket-sharp',
  'basket',
  'basketball-outline',
  'basketball-sharp',
  'basketball',
  'battery-charging-outline',
  'battery-charging-sharp',
  'battery-charging',
  'battery-dead-outline',
  'battery-dead-sharp',
  'battery-dead',
  'battery-full-outline',
  'battery-full-sharp',
  'battery-full',
  'battery-half-outline',
  'battery-half-sharp',
  'battery-half',
  'beaker-outline',
  'beaker-sharp',
  'beaker',
  'bed-outline',
  'bed-sharp',
  'bed',
  'beer-outline',
  'beer-sharp',
  'beer',
  'bicycle-outline',
  'bicycle-sharp',
  'bicycle',
  'bluetooth-outline',
  'bluetooth-sharp',
  'bluetooth',
  'boat-outline',
  'boat-sharp',
  'boat',
  'body-outline',
  'body-sharp',
  'body',
  'bonfire-outline',
  'bonfire-sharp',
  'bonfire',
  'book-outline',
  'book-sharp',
  'book',
  'bookmark-outline',
  'bookmark-sharp',
  'bookmark',
  'bookmarks-outline',
  'bookmarks-sharp',
  'bookmarks',
  'bowling-ball-outline',
  'bowling-ball-sharp',
  'bowling-ball',
  'briefcase-outline',
  'briefcase-sharp',
  'briefcase',
  'browsers-outline',
  'browsers-sharp',
  'browsers',
  'brush-outline',
  'brush-sharp',
  'brush',
  'bug-outline',
  'bug-sharp',
  'bug',
  'build-outline',
  'build-sharp',
  'build',
  'bulb-outline',
  'bulb-sharp',
  'bulb',
  'bus-outline',
  'bus-sharp',
  'bus',
  'business-outline',
  'business-sharp',
  'business',
  'cafe-outline',
  'cafe-sharp',
  'cafe',
  'calculator-outline',
  'calculator-sharp',
  'calculator',
  'calendar-clear-outline',
  'calendar-clear-sharp',
  'calendar-clear',
  'calendar-number-outline',
  'calendar-number-sharp',
  'calendar-number',
  'calendar-outline',
  'calendar-sharp',
  'calendar',
  'call-outline',
  'call-sharp',
  'call',
  'camera-outline',
  'camera-reverse-outline',
  'camera-reverse-sharp',
  'camera-reverse',
  'camera-sharp',
  'camera',
  'car-outline',
  'car-sharp',
  'car-sport-outline',
  'car-sport-sharp',
  'car-sport',
  'car',
  'card-outline',
  'card-sharp',
  'card',
  'caret-back-circle-outline',
  'caret-back-circle-sharp',
  'caret-back-circle',
  'caret-back-outline',
  'caret-back-sharp',
  'caret-back',
  'caret-down-circle-outline',
  'caret-down-circle-sharp',
  'caret-down-circle',
  'caret-down-outline',
  'caret-down-sharp',
  'caret-down',
  'caret-forward-circle-outline',
  'caret-forward-circle-sharp',
  'caret-forward-circle',
  'caret-forward-outline',
  'caret-forward-sharp',
  'caret-forward',
  'caret-up-circle-outline',
  'caret-up-circle-sharp',
  'caret-up-circle',
  'caret-up-outline',
  'caret-up-sharp',
  'caret-up',
  'cart-outline',
  'cart-sharp',
  'cart',
  'cash-outline',
  'cash-sharp',
  'cash',
  'cellular-outline',
  'cellular-sharp',
  'cellular',
  'chatbox-ellipses-outline',
  'chatbox-ellipses-sharp',
  'chatbox-ellipses',
  'chatbox-outline',
  'chatbox-sharp',
  'chatbox',
  'chatbubble-ellipses-outline',
  'chatbubble-ellipses-sharp',
  'chatbubble-ellipses',
  'chatbubble-outline',
  'chatbubble-sharp',
  'chatbubble',
  'chatbubbles-outline',
  'chatbubbles-sharp',
  'chatbubbles',
  'checkbox-outline',
  'checkbox-sharp',
  'checkbox',
  'checkmark-circle-outline',
  'checkmark-circle-sharp',
  'checkmark-circle',
  'checkmark-done-circle-outline',
  'checkmark-done-circle-sharp',
  'checkmark-done-circle',
  'checkmark-done-outline',
  'checkmark-done-sharp',
  'checkmark-done',
  'checkmark-outline',
  'checkmark-sharp',
  'checkmark',
  'chevron-back-circle-outline',
  'chevron-back-circle-sharp',
  'chevron-back-circle',
  'chevron-back-outline',
  'chevron-back-sharp',
  'chevron-back',
  'chevron-down-circle-outline',
  'chevron-down-circle-sharp',
  'chevron-down-circle',
  'chevron-down-outline',
  'chevron-down-sharp',
  'chevron-down',
  'chevron-forward-circle-outline',
  'chevron-forward-circle-sharp',
  'chevron-forward-circle',
  'chevron-forward-outline',
  'chevron-forward-sharp',
  'chevron-forward',
  'chevron-up-circle-outline',
  'chevron-up-circle-sharp',
  'chevron-up-circle',
  'chevron-up-outline',
  'chevron-up-sharp',
  'chevron-up',
  'clipboard-outline',
  'clipboard-sharp',
  'clipboard',
  'close-circle-outline',
  'close-circle-sharp',
  'close-circle',
  'close-outline',
  'close-sharp',
  'close',
  'cloud-circle-outline',
  'cloud-circle-sharp',
  'cloud-circle',
  'cloud-done-outline',
  'cloud-done-sharp',
  'cloud-done',
  'cloud-download-outline',
  'cloud-download-sharp',
  'cloud-download',
  'cloud-offline-outline',
  'cloud-offline-sharp',
  'cloud-offline',
  'cloud-outline',
  'cloud-sharp',
  'cloud-upload-outline',
  'cloud-upload-sharp',
  'cloud-upload',
  'cloud',
  'cloudy-night-outline',
  'cloudy-night-sharp',
  'cloudy-night',
  'cloudy-outline',
  'cloudy-sharp',
  'cloudy',
  'code-download-outline',
  'code-download-sharp',
  'code-download',
  'code-outline',
  'code-sharp',
  'code-slash-outline',
  'code-slash-sharp',
  'code-slash',
  'code-working-outline',
  'code-working-sharp',
  'code-working',
  'code',
  'cog-outline',
  'cog-sharp',
  'cog',
  'color-fill-outline',
  'color-fill-sharp',
  'color-fill',
  'color-filter-outline',
  'color-filter-sharp',
  'color-filter',
  'color-palette-outline',
  'color-palette-sharp',
  'color-palette',
  'color-wand-outline',
  'color-wand-sharp',
  'color-wand',
  'compass-outline',
  'compass-sharp',
  'compass',
  'construct-outline',
  'construct-sharp',
  'construct',
  'contract-outline',
  'contract-sharp',
  'contract',
  'contrast-outline',
  'contrast-sharp',
  'contrast',
  'copy-outline',
  'copy-sharp',
  'copy',
  'create-outline',
  'create-sharp',
  'create',
  'crop-outline',
  'crop-sharp',
  'crop',
  'cube-outline',
  'cube-sharp',
  'cube',
  'cut-outline',
  'cut-sharp',
  'cut',
  'desktop-outline',
  'desktop-sharp',
  'desktop',
  'diamond-outline',
  'diamond-sharp',
  'diamond',
  'dice-outline',
  'dice-sharp',
  'dice',
  'disc-outline',
  'disc-sharp',
  'disc',
  'document-attach-outline',
  'document-attach-sharp',
  'document-attach',
  'document-lock-outline',
  'document-lock-sharp',
  'document-lock',
  'document-outline',
  'document-sharp',
  'document-text-outline',
  'document-text-sharp',
  'document-text',
  'document',
  'documents-outline',
  'documents-sharp',
  'documents',
  'download-outline',
  'download-sharp',
  'download',
  'duplicate-outline',
  'duplicate-sharp',
  'duplicate',
  'ear-outline',
  'ear-sharp',
  'ear',
  'earth-outline',
  'earth-sharp',
  'earth',
  'easel-outline',
  'easel-sharp',
  'easel',
  'egg-outline',
  'egg-sharp',
  'egg',
  'ellipse-outline',
  'ellipse-sharp',
  'ellipse',
  'ellipsis-horizontal-circle-outline',
  'ellipsis-horizontal-circle-sharp',
  'ellipsis-horizontal-circle',
  'ellipsis-horizontal-outline',
  'ellipsis-horizontal-sharp',
  'ellipsis-horizontal',
  'ellipsis-vertical-circle-outline',
  'ellipsis-vertical-circle-sharp',
  'ellipsis-vertical-circle',
  'ellipsis-vertical-outline',
  'ellipsis-vertical-sharp',
  'ellipsis-vertical',
  'enter-outline',
  'enter-sharp',
  'enter',
  'exit-outline',
  'exit-sharp',
  'exit',
  'expand-outline',
  'expand-sharp',
  'expand',
  'extension-puzzle-outline',
  'extension-puzzle-sharp',
  'extension-puzzle',
  'eye-off-outline',
  'eye-off-sharp',
  'eye-off',
  'eye-outline',
  'eye-sharp',
  'eye',
  'eyedrop-outline',
  'eyedrop-sharp',
  'eyedrop',
  'fast-food-outline',
  'fast-food-sharp',
  'fast-food',
  'female-outline',
  'female-sharp',
  'female',
  'file-tray-full-outline',
  'file-tray-full-sharp',
  'file-tray-full',
  'file-tray-outline',
  'file-tray-sharp',
  'file-tray-stacked-outline',
  'file-tray-stacked-sharp',
  'file-tray-stacked',
  'file-tray',
  'film-outline',
  'film-sharp',
  'film',
  'filter-circle-outline',
  'filter-circle-sharp',
  'filter-circle',
  'filter-outline',
  'filter-sharp',
  'filter',
  'finger-print-outline',
  'finger-print-sharp',
  'finger-print',
  'fish-outline',
  'fish-sharp',
  'fish',
  'fitness-outline',
  'fitness-sharp',
  'fitness',
  'flag-outline',
  'flag-sharp',
  'flag',
  'flame-outline',
  'flame-sharp',
  'flame',
  'flash-off-outline',
  'flash-off-sharp',
  'flash-off',
  'flash-outline',
  'flash-sharp',
  'flash',
  'flashlight-outline',
  'flashlight-sharp',
  'flashlight',
  'flask-outline',
  'flask-sharp',
  'flask',
  'flower-outline',
  'flower-sharp',
  'flower',
  'folder-open-outline',
  'folder-open-sharp',
  'folder-open',
  'folder-outline',
  'folder-sharp',
  'folder',
  'football-outline',
  'football-sharp',
  'football',
  'footsteps-outline',
  'footsteps-sharp',
  'footsteps',
  'funnel-outline',
  'funnel-sharp',
  'funnel',
  'game-controller-outline',
  'game-controller-sharp',
  'game-controller',
  'gift-outline',
  'gift-sharp',
  'gift',
  'git-branch-outline',
  'git-branch-sharp',
  'git-branch',
  'git-commit-outline',
  'git-commit-sharp',
  'git-commit',
  'git-compare-outline',
  'git-compare-sharp',
  'git-compare',
  'git-merge-outline',
  'git-merge-sharp',
  'git-merge',
  'git-network-outline',
  'git-network-sharp',
  'git-network',
  'git-pull-request-outline',
  'git-pull-request-sharp',
  'git-pull-request',
  'glasses-outline',
  'glasses-sharp',
  'glasses',
  'globe-outline',
  'globe-sharp',
  'globe',
  'golf-outline',
  'golf-sharp',
  'golf',
  'grid-outline',
  'grid-sharp',
  'grid',
  'hammer-outline',
  'hammer-sharp',
  'hammer',
  'hand-left-outline',
  'hand-left-sharp',
  'hand-left',
  'hand-right-outline',
  'hand-right-sharp',
  'hand-right',
  'happy-outline',
  'happy-sharp',
  'happy',
  'hardware-chip-outline',
  'hardware-chip-sharp',
  'hardware-chip',
  'headset-outline',
  'headset-sharp',
  'headset',
  'heart-circle-outline',
  'heart-circle-sharp',
  'heart-circle',
  'heart-dislike-circle-outline',
  'heart-dislike-circle-sharp',
  'heart-dislike-circle',
  'heart-dislike-outline',
  'heart-dislike-sharp',
  'heart-dislike',
  'heart-half-outline',
  'heart-half-sharp',
  'heart-half',
  'heart-outline',
  'heart-sharp',
  'heart',
  'help-buoy-outline',
  'help-buoy-sharp',
  'help-buoy',
  'help-circle-outline',
  'help-circle-sharp',
  'help-circle',
  'help-outline',
  'help-sharp',
  'help',
  'home-outline',
  'home-sharp',
  'home',
  'hourglass-outline',
  'hourglass-sharp',
  'hourglass',
  'ice-cream-outline',
  'ice-cream-sharp',
  'ice-cream',
  'icons',
  'id-card-outline',
  'id-card-sharp',
  'id-card',
  'image-outline',
  'image-sharp',
  'image',
  'images-outline',
  'images-sharp',
  'images',
  'infinite-outline',
  'infinite-sharp',
  'infinite',
  'information-circle-outline',
  'information-circle-sharp',
  'information-circle',
  'information-outline',
  'information-sharp',
  'information',
  'invert-mode-outline',
  'invert-mode-sharp',
  'invert-mode',
  'journal-outline',
  'journal-sharp',
  'journal',
  'key-outline',
  'key-sharp',
  'key',
  'keypad-outline',
  'keypad-sharp',
  'keypad',
  'language-outline',
  'language-sharp',
  'language',
  'laptop-outline',
  'laptop-sharp',
  'laptop',
  'layers-outline',
  'layers-sharp',
  'layers',
  'leaf-outline',
  'leaf-sharp',
  'leaf',
  'library-outline',
  'library-sharp',
  'library',
  'link-outline',
  'link-sharp',
  'link',
  'list-circle-outline',
  'list-circle-sharp',
  'list-circle',
  'list-outline',
  'list-sharp',
  'list',
  'locate-outline',
  'locate-sharp',
  'locate',
  'location-outline',
  'location-sharp',
  'location',
  'lock-closed-outline',
  'lock-closed-sharp',
  'lock-closed',
  'lock-open-outline',
  'lock-open-sharp',
  'lock-open',
  'log-in-outline',
  'log-in-sharp',
  'log-in',
  'log-out-outline',
  'log-out-sharp',
  'log-out',
  'logo-alipay',
  'logo-amazon',
  'logo-amplify',
  'logo-android',
  'logo-angular',
  'logo-apple-appstore',
  'logo-apple-ar',
  'logo-apple',
  'logo-behance',
  'logo-bitbucket',
  'logo-bitcoin',
  'logo-buffer',
  'logo-capacitor',
  'logo-chrome',
  'logo-closed-captioning',
  'logo-codepen',
  'logo-css3',
  'logo-designernews',
  'logo-deviantart',
  'logo-discord',
  'logo-docker',
  'logo-dribbble',
  'logo-dropbox',
  'logo-edge',
  'logo-electron',
  'logo-euro',
  'logo-facebook',
  'logo-figma',
  'logo-firebase',
  'logo-firefox',
  'logo-flickr',
  'logo-foursquare',
  'logo-github',
  'logo-gitlab',
  'logo-google-playstore',
  'logo-google',
  'logo-hackernews',
  'logo-html5',
  'logo-instagram',
  'logo-ionic',
  'logo-ionitron',
  'logo-javascript',
  'logo-laravel',
  'logo-linkedin',
  'logo-markdown',
  'logo-mastodon',
  'logo-medium',
  'logo-microsoft',
  'logo-no-smoking',
  'logo-nodejs',
  'logo-npm',
  'logo-octocat',
  'logo-paypal',
  'logo-pinterest',
  'logo-playstation',
  'logo-pwa',
  'logo-python',
  'logo-react',
  'logo-reddit',
  'logo-rss',
  'logo-sass',
  'logo-skype',
  'logo-slack',
  'logo-snapchat',
  'logo-soundcloud',
  'logo-stackoverflow',
  'logo-steam',
  'logo-stencil',
  'logo-tableau',
  'logo-tiktok',
  'logo-tumblr',
  'logo-tux',
  'logo-twitch',
  'logo-twitter',
  'logo-usd',
  'logo-venmo',
  'logo-vercel',
  'logo-vimeo',
  'logo-vk',
  'logo-vue',
  'logo-web-component',
  'logo-wechat',
  'logo-whatsapp',
  'logo-windows',
  'logo-wordpress',
  'logo-xbox',
  'logo-xing',
  'logo-yahoo',
  'logo-yen',
  'logo-youtube',
  'magnet-outline',
  'magnet-sharp',
  'magnet',
  'mail-open-outline',
  'mail-open-sharp',
  'mail-open',
  'mail-outline',
  'mail-sharp',
  'mail-unread-outline',
  'mail-unread-sharp',
  'mail-unread',
  'mail',
  'male-female-outline',
  'male-female-sharp',
  'male-female',
  'male-outline',
  'male-sharp',
  'male',
  'man-outline',
  'man-sharp',
  'man',
  'map-outline',
  'map-sharp',
  'map',
  'medal-outline',
  'medal-sharp',
  'medal',
  'medical-outline',
  'medical-sharp',
  'medical',
  'medkit-outline',
  'medkit-sharp',
  'medkit',
  'megaphone-outline',
  'megaphone-sharp',
  'megaphone',
  'menu-outline',
  'menu-sharp',
  'menu',
  'mic-circle-outline',
  'mic-circle-sharp',
  'mic-circle',
  'mic-off-circle-outline',
  'mic-off-circle-sharp',
  'mic-off-circle',
  'mic-off-outline',
  'mic-off-sharp',
  'mic-off',
  'mic-outline',
  'mic-sharp',
  'mic',
  'moon-outline',
  'moon-sharp',
  'moon',
  'move-outline',
  'move-sharp',
  'move',
  'musical-note-outline',
  'musical-note-sharp',
  'musical-note',
  'musical-notes-outline',
  'musical-notes-sharp',
  'musical-notes',
  'navigate-circle-outline',
  'navigate-circle-sharp',
  'navigate-circle',
  'navigate-outline',
  'navigate-sharp',
  'navigate',
  'newspaper-outline',
  'newspaper-sharp',
  'newspaper',
  'notifications-circle-outline',
  'notifications-circle-sharp',
  'notifications-circle',
  'notifications-off-circle-outline',
  'notifications-off-circle-sharp',
  'notifications-off-circle',
  'notifications-off-outline',
  'notifications-off-sharp',
  'notifications-off',
  'notifications-outline',
  'notifications-sharp',
  'notifications',
  'nuclear-outline',
  'nuclear-sharp',
  'nuclear',
  'nutrition-outline',
  'nutrition-sharp',
  'nutrition',
  'open-outline',
  'open-sharp',
  'open',
  'options-outline',
  'options-sharp',
  'options',
  'paper-plane-outline',
  'paper-plane-sharp',
  'paper-plane',
  'partly-sunny-outline',
  'partly-sunny-sharp',
  'partly-sunny',
  'pause-circle-outline',
  'pause-circle-sharp',
  'pause-circle',
  'pause-outline',
  'pause-sharp',
  'pause',
  'paw-outline',
  'paw-sharp',
  'paw',
  'pencil-outline',
  'pencil-sharp',
  'pencil',
  'people-circle-outline',
  'people-circle-sharp',
  'people-circle',
  'people-outline',
  'people-sharp',
  'people',
  'person-add-outline',
  'person-add-sharp',
  'person-add',
  'person-circle-outline',
  'person-circle-sharp',
  'person-circle',
  'person-outline',
  'person-remove-outline',
  'person-remove-sharp',
  'person-remove',
  'person-sharp',
  'person',
  'phone-landscape-outline',
  'phone-landscape-sharp',
  'phone-landscape',
  'phone-portrait-outline',
  'phone-portrait-sharp',
  'phone-portrait',
  'pie-chart-outline',
  'pie-chart-sharp',
  'pie-chart',
  'pin-outline',
  'pin-sharp',
  'pin',
  'pint-outline',
  'pint-sharp',
  'pint',
  'pizza-outline',
  'pizza-sharp',
  'pizza',
  'planet-outline',
  'planet-sharp',
  'planet',
  'play-back-circle-outline',
  'play-back-circle-sharp',
  'play-back-circle',
  'play-back-outline',
  'play-back-sharp',
  'play-back',
  'play-circle-outline',
  'play-circle-sharp',
  'play-circle',
  'play-forward-circle-outline',
  'play-forward-circle-sharp',
  'play-forward-circle',
  'play-forward-outline',
  'play-forward-sharp',
  'play-forward',
  'play-outline',
  'play-sharp',
  'play-skip-back-circle-outline',
  'play-skip-back-circle-sharp',
  'play-skip-back-circle',
  'play-skip-back-outline',
  'play-skip-back-sharp',
  'play-skip-back',
  'play-skip-forward-circle-outline',
  'play-skip-forward-circle-sharp',
  'play-skip-forward-circle',
  'play-skip-forward-outline',
  'play-skip-forward-sharp',
  'play-skip-forward',
  'play',
  'podium-outline',
  'podium-sharp',
  'podium',
  'power-outline',
  'power-sharp',
  'power',
  'pricetag-outline',
  'pricetag-sharp',
  'pricetag',
  'pricetags-outline',
  'pricetags-sharp',
  'pricetags',
  'print-outline',
  'print-sharp',
  'print',
  'prism-outline',
  'prism-sharp',
  'prism',
  'pulse-outline',
  'pulse-sharp',
  'pulse',
  'push-outline',
  'push-sharp',
  'push',
  'qr-code-outline',
  'qr-code-sharp',
  'qr-code',
  'radio-button-off-outline',
  'radio-button-off-sharp',
  'radio-button-off',
  'radio-button-on-outline',
  'radio-button-on-sharp',
  'radio-button-on',
  'radio-outline',
  'radio-sharp',
  'radio',
  'rainy-outline',
  'rainy-sharp',
  'rainy',
  'reader-outline',
  'reader-sharp',
  'reader',
  'receipt-outline',
  'receipt-sharp',
  'receipt',
  'recording-outline',
  'recording-sharp',
  'recording',
  'refresh-circle-outline',
  'refresh-circle-sharp',
  'refresh-circle',
  'refresh-outline',
  'refresh-sharp',
  'refresh',
  'reload-circle-outline',
  'reload-circle-sharp',
  'reload-circle',
  'reload-outline',
  'reload-sharp',
  'reload',
  'remove-circle-outline',
  'remove-circle-sharp',
  'remove-circle',
  'remove-outline',
  'remove-sharp',
  'remove',
  'reorder-four-outline',
  'reorder-four-sharp',
  'reorder-four',
  'reorder-three-outline',
  'reorder-three-sharp',
  'reorder-three',
  'reorder-two-outline',
  'reorder-two-sharp',
  'reorder-two',
  'repeat-outline',
  'repeat-sharp',
  'repeat',
  'resize-outline',
  'resize-sharp',
  'resize',
  'restaurant-outline',
  'restaurant-sharp',
  'restaurant',
  'return-down-back-outline',
  'return-down-back-sharp',
  'return-down-back',
  'return-down-forward-outline',
  'return-down-forward-sharp',
  'return-down-forward',
  'return-up-back-outline',
  'return-up-back-sharp',
  'return-up-back',
  'return-up-forward-outline',
  'return-up-forward-sharp',
  'return-up-forward',
  'ribbon-outline',
  'ribbon-sharp',
  'ribbon',
  'rocket-outline',
  'rocket-sharp',
  'rocket',
  'rose-outline',
  'rose-sharp',
  'rose',
  'sad-outline',
  'sad-sharp',
  'sad',
  'save-outline',
  'save-sharp',
  'save',
  'scale-outline',
  'scale-sharp',
  'scale',
  'scan-circle-outline',
  'scan-circle-sharp',
  'scan-circle',
  'scan-outline',
  'scan-sharp',
  'scan',
  'school-outline',
  'school-sharp',
  'school',
  'search-circle-outline',
  'search-circle-sharp',
  'search-circle',
  'search-outline',
  'search-sharp',
  'search',
  'send-outline',
  'send-sharp',
  'send',
  'server-outline',
  'server-sharp',
  'server',
  'settings-outline',
  'settings-sharp',
  'settings',
  'shapes-outline',
  'shapes-sharp',
  'shapes',
  'share-outline',
  'share-sharp',
  'share-social-outline',
  'share-social-sharp',
  'share-social',
  'share',
  'shield-checkmark-outline',
  'shield-checkmark-sharp',
  'shield-checkmark',
  'shield-half-outline',
  'shield-half-sharp',
  'shield-half',
  'shield-outline',
  'shield-sharp',
  'shield',
  'shirt-outline',
  'shirt-sharp',
  'shirt',
  'shuffle-outline',
  'shuffle-sharp',
  'shuffle',
  'skull-outline',
  'skull-sharp',
  'skull',
  'snow-outline',
  'snow-sharp',
  'snow',
  'sparkles-outline',
  'sparkles-sharp',
  'sparkles',
  'speedometer-outline',
  'speedometer-sharp',
  'speedometer',
  'square-outline',
  'square-sharp',
  'square',
  'star-half-outline',
  'star-half-sharp',
  'star-half',
  'star-outline',
  'star-sharp',
  'star',
  'stats-chart-outline',
  'stats-chart-sharp',
  'stats-chart',
  'stop-circle-outline',
  'stop-circle-sharp',
  'stop-circle',
  'stop-outline',
  'stop-sharp',
  'stop',
  'stopwatch-outline',
  'stopwatch-sharp',
  'stopwatch',
  'storefront-outline',
  'storefront-sharp',
  'storefront',
  'subway-outline',
  'subway-sharp',
  'subway',
  'sunny-outline',
  'sunny-sharp',
  'sunny',
  'swap-horizontal-outline',
  'swap-horizontal-sharp',
  'swap-horizontal',
  'swap-vertical-outline',
  'swap-vertical-sharp',
  'swap-vertical',
  'sync-circle-outline',
  'sync-circle-sharp',
  'sync-circle',
  'sync-outline',
  'sync-sharp',
  'sync',
  'tablet-landscape-outline',
  'tablet-landscape-sharp',
  'tablet-landscape',
  'tablet-portrait-outline',
  'tablet-portrait-sharp',
  'tablet-portrait',
  'telescope-outline',
  'telescope-sharp',
  'telescope',
  'tennisball-outline',
  'tennisball-sharp',
  'tennisball',
  'terminal-outline',
  'terminal-sharp',
  'terminal',
  'text-outline',
  'text-sharp',
  'text',
  'thermometer-outline',
  'thermometer-sharp',
  'thermometer',
  'thumbs-down-outline',
  'thumbs-down-sharp',
  'thumbs-down',
  'thumbs-up-outline',
  'thumbs-up-sharp',
  'thumbs-up',
  'thunderstorm-outline',
  'thunderstorm-sharp',
  'thunderstorm',
  'ticket-outline',
  'ticket-sharp',
  'ticket',
  'time-outline',
  'time-sharp',
  'time',
  'timer-outline',
  'timer-sharp',
  'timer',
  'today-outline',
  'today-sharp',
  'today',
  'toggle-outline',
  'toggle-sharp',
  'toggle',
  'trail-sign-outline',
  'trail-sign-sharp',
  'trail-sign',
  'train-outline',
  'train-sharp',
  'train',
  'transgender-outline',
  'transgender-sharp',
  'transgender',
  'trash-bin-outline',
  'trash-bin-sharp',
  'trash-bin',
  'trash-outline',
  'trash-sharp',
  'trash',
  'trending-down-outline',
  'trending-down-sharp',
  'trending-down',
  'trending-up-outline',
  'trending-up-sharp',
  'trending-up',
  'triangle-outline',
  'triangle-sharp',
  'triangle',
  'trophy-outline',
  'trophy-sharp',
  'trophy',
  'tv-outline',
  'tv-sharp',
  'tv',
  'umbrella-outline',
  'umbrella-sharp',
  'umbrella',
  'unlink-outline',
  'unlink-sharp',
  'unlink',
  'videocam-off-outline',
  'videocam-off-sharp',
  'videocam-off',
  'videocam-outline',
  'videocam-sharp',
  'videocam',
  'volume-high-outline',
  'volume-high-sharp',
  'volume-high',
  'volume-low-outline',
  'volume-low-sharp',
  'volume-low',
  'volume-medium-outline',
  'volume-medium-sharp',
  'volume-medium',
  'volume-mute-outline',
  'volume-mute-sharp',
  'volume-mute',
  'volume-off-outline',
  'volume-off-sharp',
  'volume-off',
  'walk-outline',
  'walk-sharp',
  'walk',
  'wallet-outline',
  'wallet-sharp',
  'wallet',
  'warning-outline',
  'warning-sharp',
  'warning',
  'watch-outline',
  'watch-sharp',
  'watch',
  'water-outline',
  'water-sharp',
  'water',
  'wifi-outline',
  'wifi-sharp',
  'wifi',
  'wine-outline',
  'wine-sharp',
  'wine',
  'woman-outline',
  'woman-sharp',
  'woman',
];
let currentRow = {};
const showSelector = (spec, index) => {
  currentRow = {
    spec,
    index
  }
  showSelectorWin.value = true;
}
const createSpec = () => {
  emit('change', 'create', {
    name: 'battery-half',
    index: props.specs && props.specs.length || 0 // 暂时用不上
  })
}
const selectIcon = (icon) => {
  emit('change', 'update', {
    name: icon,
    index: currentRow.index
  });
  showSelectorWin.value = false;
}
const moveUp = (index) => {
  emit('change', 'moveUp', { index: index })
}
const moveDown = (index) => {
  emit('change', 'moveDown', { index: index })
}
const deleteSpec = (index) => {
  emit('change', 'delete', { index: index })
}
const handleClose = () => {
  showSelectorWin.value = false;
}
</script>

<template>
<div class="spec-container">
  <div class="row" v-for="(spec, index) in specs">
    <div class="selected-icon" @click="showSelector(spec, index)">
      <ion-icon :name="spec.name"></ion-icon>
    </div>
    <div class="text">
      <input placeholder="输入规格文字" v-model="spec.text"/>
    </div>
    <div class="op">
      <div class="icons">
        <div class="icon"><ion-icon name="arrow-up" @click="moveUp(index)"></ion-icon></div>
        <div class="icon"><ion-icon name="arrow-down" @click="moveDown(index)"></ion-icon></div>
        <div class="icon"><ion-icon name="close" @click="deleteSpec(index)"></ion-icon></div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="icon add-new">
      <span>新增规格</span>
      <ion-icon name="add-circle" @click="createSpec"></ion-icon>
    </div>
  </div>
  <el-dialog
      v-model="showSelectorWin"
      title="选择图标"
      width="30%"
      :before-close="handleClose"
  >
    <div class="icon-selector">
      <div class="icon-grid" v-for="icon in icons" @click="selectIcon(icon)">
        <ion-icon :name="icon"></ion-icon>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="showSelectorWin = false">Cancel</el-button>
      </span>
    </template>
  </el-dialog>
</div>
</template>

<style lang="scss" scoped>
.spec-container {
  width: 100%;
}
.row {
  margin-bottom: 5px;
  height: 48px;
  line-height: 48px;
  .selected-icon {
    display: inline-block;
    float: left;
    width: 48px;
    font-size: 24px;
    cursor: pointer;
    :hover {
      color: #5FAFF1;
    }

    ion-icon {
      vertical-align: -4px;
    }
  }
  .text {
    text-align: left;
    display: inline-block;
    width: calc(100% - 192px);
    input {
      height: 48px;
      line-height: 48px;
      outline: none;
      border: 1px solid #EAEAEA;
      width: 100%;
      color: rgba(96, 98, 102);
      border-radius: 4px;
      box-sizing: border-box;
      padding: 0 15px;
    }
  }
  .op {
    display: inline-block;
    width: 144px;
    float: right;
    padding-left: 20px;
    .icon {
      display: inline-block;
      float: left;
      width: 36px;
      font-size: 24px;
      cursor: pointer;
      transition: all .5s;
      border-radius: 4px;
      &:hover {
        background: #EFEFEF;
      }

      ion-icon {
        vertical-align: -4px;
      }
    }
  }

  .add-new {
    span {
      font-size: 18px;
      vertical-align: -5px;
      margin-right: 10px;
    }
    ion-icon {
      font-size: 32px;
      vertical-align: -15px;
      cursor: pointer;
    }
  }
}
.icon-selector {
  background: red;
  .icon-grid {
    font-size: 32px;
    cursor: pointer;
    width: 48px;
    height: 48px;
    display: inline-block;
    float: left;
    &:hover {
      color: #5FAFF1;
    }
  }
}
</style>