<script setup>
const enter = (element) => {
  const width = getComputedStyle(element).width;

  element.style.width = width;
  element.style.position = 'absolute';
  element.style.visibility = 'hidden';
  element.style.height = 'auto';

  const height = getComputedStyle(element).height;
  element.style.width = null;
  element.style.position = null;
  element.style.visibility = null;
  element.style.height = 0;

  getComputedStyle(element).height;

  requestAnimationFrame(() => {
    element.style.height = height;
  })
}
const afterEnter = (element) => {
  element.style.height = 'auto';
}
const leave = (element) => {
  const height = getComputedStyle(element).height;
  element.style.height = height;

  getComputedStyle(element).height;

  requestAnimationFrame(() => {
    element.style.height = 0;
  })
}
</script>

<template>
  <Transition
      name="expand"
      @enter="enter"
      @after-enter="afterEnter"
      @leave="leave"
  >
    <slot></slot>
  </Transition>
</template>

<style scoped>
.expand-enter-active, .expand-leave-active {
  transition: height .3s ease-out;
  overflow: hidden;
}
.expand-enter-from, .expand-leave-to {
  height: 0;
}
</style>