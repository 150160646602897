import * as Vue from 'vue'
import * as VueRouter from 'vue-router'
import App from './App.vue'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import Vue3ColorPicker from "vue3-colorpicker";
import "vue3-colorpicker/style.css";

import Home from './pages/Home.vue'
import Product from './pages/Product.vue'
import ProductDetail from './pages/ProductDetail.vue'
import Dashboard from './pages/dashboard/index.vue'
import Authenticate from './pages/Authenticate.vue'
import ProductManagement from './pages/dashboard/ProductManagement.vue'
import CategoryManagement from './pages/dashboard/CategoryManagement.vue'
import Miscellaneous from './pages/dashboard/Miscellaneous.vue'
import ContactList from './pages/dashboard/ContactList.vue'

import Contact from './pages/Contact.vue'
import About from './pages/About.vue'

const routes = [{
    path: '/',
    name: 'home',
    component: Home
}, {
    path: '/auth',
    component: Authenticate
}, {
    path: '/product',
    name: 'product',
    component: Product
}, {
    path: '/product_detail',
    name: 'product_detail',
    component: ProductDetail
}, {
    path: '/contact',
    name: 'contact',
    component: Contact
}, {
    path: '/about',
    name: 'about',
    component: About
}, {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    children: [{
        path: 'product',
        component: ProductManagement
    }, {
        path: 'category',
        component: CategoryManagement
    }, {
        path: 'contact',
        component: ContactList
    }, {
        path: 'miscellaneous',
        component: Miscellaneous
    }]
},]

const router = VueRouter.createRouter({
    history: VueRouter.createWebHashHistory(),
    routes
})

const app = Vue.createApp(App)

app.use(router)

// 注册 Element UI
app.use(ElementPlus)

// 注册 Element UI 图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.use(Vue3ColorPicker)

app.mount('#app')