<script>
import { http, constants } from "../lib";
import { useRouter } from 'vue-router';
import ItemCard from './components/ItemCard.vue'
import Banner from "./components/Banner.vue";
import Footer from "./components/Footer.vue";
export default {
  components: {
    ItemCard,
    Banner,
    Footer
  },
  data() {
    return {
      productList: [],
      colors: ['#C6B69C', '#9ABAAB', '#6D687F', '#8F7461', '#B24A4B', '#F3AFA6', '#ECC670', '#909869'],
      showContent: false,
      cards: [1, 2, 3]
    }
  },
  watch: {
    '$route.query': {
      deep: true,
      immediate: true,
      handler: function() {
        this.getProductList();
      }
    }
  },
  methods: {
    async getProductList() {
      const query = this.$router.currentRoute.value.query;
      let data = null;
      if (query.category) {
        data = {
          category: query.category
        }
      }
      let productRes = await http.get('product', data);
      let products = productRes.data;
      let list = [];
      for (const item of products) {
        let product = {
          id: item.id,
          title: item.name,
          subtitle: item.caption,
          image: constants.image_root +  item.image_name
        }
        let spec = item.spec.split('@@');
        product.spec = spec.map(v => {
          let portion = v.split('##')
          return {
            icon: portion[0],
            label: portion[1],
          }
        })
        let cardsRes = await http.get('sku', { product_id: item.id });
        if (cardsRes.data && cardsRes.data.length) {
          product.skus = cardsRes.data;
        } else {
          product.skus = [];
        }
        product.color = this.colors[(item.id % this.colors.length)]
        list.push(product)
      }

      this.productList = list;
      this.showContent = true;
    },
  },
  created() {
  }
}

</script>

<template>
  <Banner />
  <div class="skeleton" v-if="!showContent">
    <el-card class="card" v-for="card in cards">
      <el-skeleton animated :rows="5" />
      <br />
      <el-skeleton style="--el-skeleton-circle-size: 100px">
        <template #template>
          <el-skeleton-item variant="circle" />
        </template>
      </el-skeleton>
    </el-card>
  </div>
  <template v-for="product in productList" v-if="showContent">
    <ItemCard :product="product"></ItemCard>
  </template>
  <Footer />
</template>

<style scoped lang="scss">
.skeleton {
  .card {
    width: 1000px;
    height: 400px;
    margin: 10px auto;
    text-align: left;
  }
}
</style>