<script setup>
const links = [{
  title: 'STORE',
  children: [{
    label: 'Earphones',
    link: ''
  }, {
    label: 'Speakers',
    link: ''
  }, {
    label: 'Limited editions',
    link: ''
  }, {
    label: 'Guarantee',
    link: ''
  }]
}, {
  title: 'ABOUT SUPERBLUE',
  children: [{
    label: 'Who are we?',
    link: ''
  }, {
    label: 'Where to find our products'
  }, {
    label: 'Distribute our products'
  }, {
    label: 'Partnership'
  }]
}, {
  title: 'CUSTOMER SERVICES',
  children: [{
    label: 'Support'
  }, {
    label: 'Returns'
  }, {
    label: 'Notices'
  }, {
    label: 'Legal informations'
  }]
}, {
  title: 'FOLLOW US',
  children: [{
    label: 'Facebook'
  }, {
    label: 'Instagram'
  }, {
    label: 'Linkedin'
  }, {
    label: 'Youtube'
  }]
}]
const year = new Date().getFullYear()
</script>

<template>
<div class="footer">
  <div class="wrapper">
    <div class="follow">
      <div class="logo-container">
        <a href="https://www.facebook.com">
          <ion-icon name="logo-facebook" class="icon-item"></ion-icon>
        </a>
        <a>
          <ion-icon name="logo-instagram" class="icon-item"></ion-icon>
        </a>
        <a>
          <ion-icon name="logo-linkedin" class="icon-item"></ion-icon>
        </a>
        <a>
          <ion-icon name="logo-youtube" class="icon-item"></ion-icon>
        </a>
      </div>
    </div>
    <div class="subscribe">
      <div class="site-logo">
        <img alt="SuperblueTech Logo" src="../../assets/images/footer-logo.png" />
      </div>
    </div>
    <div class="links">
      <div class="links-container">
        <div class="link-block" v-for="link in links" :key="link.title">
          <div class="title">{{ link.title }}</div>
          <ul>
            <li class="link-item" v-for="item in link.children" :key="item.label">
              {{ item.label }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="trademark">
      <div class="text">
        <span>@SUPERBLUE TECH {{ year }}</span>
      </div>
    </div>
  </div>
</div>
</template>

<style lang="scss" scoped>
.footer {
  background: #F2F2F2;
  overflow: hidden;
  .follow {
    margin-top: 30px;
    margin-bottom: 40px;
    a {
      color: #333333;
    }
    .icon-item {
      font-size: 24px;
      margin: 0 14px;
    }
  }
  .subscribe {
    .site-logo {
      margin: 35px 0;
      font-size: 64px;
      height: 56px;
      img {
        height: 100%;
      }
    }
    .subscribe-text {
      color: #1D1D1FD6;
      font-family: "Roboto", Sans-serif;
      font-size: 19px;
      font-weight: 300;
      font-style: italic;
    }
    .subscribe-box {
      margin: 20px 0;

      input {
        background-color: #FFFFFF;
        border-width: 0;
        font-size: 14px;
        vertical-align: middle;
        line-height: 1.8;
        height: 45px;
        padding: 7px 20px;
        min-height: 59px;
        transition: all 0.3s ease;
        box-sizing: border-box;
        outline: none;
        width: 450px;
      }
      .subscribe-button {
        display: inline-block;
        background-color: #333333;
        color: #ffffff;
        font-size: 14px;
        font-weight: 600;
        min-height: 59px;
        line-height: 59px;
        width: 226px;
        text-align: center;
        vertical-align: middle;
      }
    }
  }
  .links {
    display: none;
    padding: 30px 0 70px 0;
    max-width: 1140px;
    margin: auto;
    text-align: center;
    font-family: "Open Sans",sans-serif;
    .links-container {
      display: flex;
      width: 100%;
      .link-block {
        width: 25%;
        .title {
          color: #000000;
          font-size: 18px;
          font-weight: 500;
          line-height: 1;
        }
        ul {
          list-style-type: none;
          margin-top: 20px;
          li {
            font-size: 14px;
            font-weight: 300;
            margin: 12px 0;
            color: #333333;
          }
        }
      }
    }
  }
  .trademark {
    width: 100%;
    background-color: #1B1B1B;
    font-size: 12px;

    padding: 15px 0 20px 0;
    .text {
      width: 1200px;
      max-width: 90%;
      margin: 0 auto;
      color: #929292;
    }
  }
}

@media (max-width: 768px) {
  .footer {
    .links {
      padding: 0;
      .links-container {
        flex-wrap: wrap;
        .link-block {
          width: 50%;
          margin-bottom: 20px;
        }
      }
    }
  }
}
@media (max-width: 576px) {
  .footer {
    .links {
      .links-container {
        flex-wrap: wrap;
        .link-block {
          width: 100%;
          margin-bottom: 20px;
        }
      }
    }
  }

}
</style>
