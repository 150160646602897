<script setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { constants, utility } from "../../lib";

defineProps({
  productItem: Object
})
const router = useRouter()
const positionStyle = (item) => {
  return {
    justifyContent: utility.format.align.style(item.align),
    paddingLeft: item.margin_left + 'px'
  }
}
const navigateTo = (button) => {
  const url = button.url;
  if (url.indexOf('http') === -1) {
    router.push({ path: url, query: button.query })
  } else {
    window.open(url)
  }
}
</script>

<template>
  <section>
    <div class="product-item" :style="{ height: productItem.height + 'px' }">
      <div class="product-image" :style="{ backgroundImage: 'url(' + productItem.background + ')' }">
        <div class="container" :style="{ left: productItem.offset }">
          <div class="content" :style="positionStyle(productItem)">
            <div class="wrapper" :style="{ marginTop: productItem.margin_top + 'px' }">
              <div class="title">
                <h2>{{ productItem.title }}</h2>
              </div>
              <div class="heading">
                <h2>{{ productItem.heading }}</h2>
              </div>
              <div class="divider">
                <span></span>
              </div>
              <div class="text">
                <p>{{ productItem.text }}</p>
              </div>
              <div class="button-wrapper" v-for="button in productItem.buttons">
                <a class="button"
                   @click="navigateTo(button)"
                   :class="{ 'white-button': button.type === 1 }">
                  <span>{{ button.text }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/common.scss';

.product-item {
  height: 540px;
  .container {
    position: relative;
  }

  .product-image {
    background-position: center center;
    background-size: cover;
    height: 100%;
    width: 100%;

    .container {
      width: 100%;
      height: 100%;

      .content {
        display: flex;
        width: 100%;
        height: 100%;
        color: #FFFFFF;
      }
    }
  }

  .wrapper {
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    width: 360px;
    height: 100%;
    text-align: left;
    .divider, .title, .heading {
      width: 100%;
    }
    .divider {
      height: 20px;
      span {
        display: block;
        border-bottom: 2px solid #FFFFFF;
        margin-top: 10px;
      }
    }
    .title h2 {
      font-size: 55px;
    }
    .heading h2 {
      font-size: 20px;
    }

    .text {
      font-family: "Open Sans",sans-serif;
      margin-bottom: 20px;
      line-height: 1.4;
      font-size: 14px;
    }
    .button-wrapper {
      width: 100%;
      .button {
        margin-top: 10px;
        @include button();

        &.white-button {
          @include button(true);
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .content {
    padding-left: 0!important;
    justify-content: center!important;
  }
}
</style>