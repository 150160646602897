<script>
import { useRoute, useRouter } from 'vue-router'
import { constants } from "../../lib";

export default {
  data() {
    return {
      name: 'product'
    }
  },
  methods: {
    /* 帮助函数 */
    getCookie(name) {
      return document.cookie.split(';').some(c => {
        return c.trim().startsWith(name + '=');
      });
    },
    deleteCookie(name, path, domain) {
      if (this.getCookie(name)) {
        document.cookie = name + "=" +
            ((path) ? ";path=" + path : "") +
            ((domain) ? ";domain=" + domain : "") +
            ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
      }
    },
  },
  computed: {
    currentPath() {
      const router = useRouter();
      const path = router.currentRoute.value.path;
      return path;
    }
  },
  mounted() {
    const route = useRoute();
    const router = useRouter();

    if (constants.prod && !this.getCookie('token')) {
      router.push({ path: '/auth' })
      return;
    }
    if (route.name === 'dashboard') {
      router.push({ path: '/dashboard/product' })
    }
  }
}
</script>

<template>
  <el-row style="height: 100%; overflow: hidden">
    <el-col :span="3" id="navigator">
      <el-menu
          class="el-menu-vertical"
          :default-active="currentPath"
          :router="true"
          text-color="#ffffff"
          active-text-color="#ffd04b"
          background-color="#545c64"
      >
        <el-menu-item index="/dashboard/product">产品管理</el-menu-item>
        <el-menu-item index="/dashboard/category">分类管理</el-menu-item>
        <el-menu-item index="/dashboard/contact">联系表单</el-menu-item>
        <el-menu-item index="/dashboard/miscellaneous">其他设置</el-menu-item>
      </el-menu>
    </el-col>
    <el-col :span="21" id="content">
      <router-view></router-view>
    </el-col>
  </el-row>
</template>

<style lang="scss" scoped>
#navigator {
  height: 100%;
}
.el-menu-vertical {
  height: 100%;
}
#content {
  height: 100%;
  padding: 20px;
  overflow-y: auto;
}
</style>