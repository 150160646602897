<script setup>
import Banner from './components/Banner.vue'
import Footer from './components/Footer.vue';
import ExpandToggle from './components/ExpandToggle.vue'
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import { http, constants } from '../lib'

const route = useRoute();
const product = ref({
  skus: [],
  cards: [],
  spec: []
});
const showContent = ref(false);
const cards = [1, 2];

const id = route.query.id;
const currentColor = ref({});
const imageList = computed(() => {
  return currentColor.value.imageArray.map(item => {
    return constants.image_root + item.name;
  })
})
const showTechSpec = ref(false)
const showBoxContent = ref(false)
const chooseColor = (sku) => {
  currentColor.value = sku;
}
async function getProductInfo() {
  let res = await http.get('product', { id: id });
  let data = res.data[0];
  let productItem = {
    name: data.name,
    caption: data.caption,
    description: data.description,
    tech_spec: data.tech_spec,
    box_content: data.box_content
  }
  let specArr = data.spec.split('@@');
  productItem.spec = specArr.map(v => {
    let s = v.split('##');
    return {
      icon: s[0],
      name: s[1]
    }
  })

  let skuRes = await http.get('sku', { product_id: data.id });

  let skus = skuRes.data;
  for (const sku of skus) {
    let imageRes = await http.get('image', { image_str: sku.images })
    sku.imageArray = imageRes.data;
  }
  productItem.skus = skus;

  if (skus.length > 0) {
    currentColor.value = skus[0];
  }

  let cardRes = await http.get('card', { product_id: data.id });
  productItem.cards = cardRes.data;

  product.value = productItem;
  showContent.value = true;
}

getProductInfo();

</script>

<template>
  <Banner></Banner>
  <div class="skeleton" v-if="!showContent">
    <el-card class="card" v-for="card in cards">
      <el-skeleton animated :rows="5" />
      <br />
      <el-skeleton style="--el-skeleton-circle-size: 100px">
        <template #template>
          <el-skeleton-item variant="circle" />
        </template>
      </el-skeleton>
    </el-card>
  </div>
  <div class="background"></div>
  <div class="content" v-if="showContent">
    <div class="summary">
      <div class="wrapper">
        <div class="slide">
          <div class="single-sku" v-if="false">
            <el-carousel
                indicator-position="outside"
                :interval="0"
                arrow="always"
                height="546px">
              <el-carousel-item v-for="image in product.skus[0].imageArray">
                <el-image
                    :src="constants.image_root + image.name"
                    fit="cover"
                />
                <img :src="c" />
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="multi-sku">
            <p class="first-row">CHOOSE YOUR</p>
            <p class="second-row">COLOR</p>
            <div class="chooser">
              <div class="color"
                   :class="{ active: currentColor.name === sku.name }"
                   @click="chooseColor(sku)"
                   v-for="sku in product.skus">
                <span>{{ sku.name }}</span>
              </div>
            </div>
            <div class="images">
              <div class="image-wrapper">
                <div class="image-item" v-for="image in currentColor.imageArray">
                  <el-image
                      style="width: 100%; height: 100%"
                      :src="constants.image_root + image.name"
                      :preview-src-list="imageList"
                      :initial-index="1"
                      fit="cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="info">
          <div class="title">{{ product.name }}</div>
          <div class="caption">{{ product.caption }}</div>
          <div class="spec">
            <div class="spec-item" v-for="spec in product.spec">
              <ion-icon :name="spec.icon" class="spec-icon"></ion-icon>
              <span class="spec-text">{{ spec.name }}</span>
            </div>
          </div>
          <div class="warranty">
            <div class="warranty-wrapper">
              <ion-icon name="shield-half-outline" class="icon"></ion-icon>
              <span>2 years warranty</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="product-card">
      <div class="card-wrapper">
        <div class="card-item" v-for="(card, index) in product.cards">
          <template v-if="index % 2 === 0">
            <div class="card-image">
              <el-image
                  style="max-width: 100%; max-height: 100%; width: 1024px;"
                  :src="constants.image_root + card.image_name"
                  :initial-index="1"
                  fit="cover"
              />
            </div>
            <div class="card-text">
              <div class="text-container">
                <div class="text-wrapper">
                  <div class="card-title">{{ card.caption }}</div>
                  <div class="card-divider">
                    <span></span>
                  </div>
                  <div class="card-description">{{ card.description }}</div>
                </div>
              </div>
            </div>
          </template>
          <template v-if="index % 2 === 1">
            <div class="card-text">
              <div class="text-container">
                <div class="text-wrapper">
                  <div class="card-title">{{ card.caption }}</div>
                  <div class="card-divider">
                    <span></span>
                  </div>
                  <div class="card-description">{{ card.description }}</div>
                </div>
              </div>
            </div>
            <div class="card-image">
              <el-image
                  style="max-width: 100%; max-height: 100%; width: 1024px;"
                  :src="constants.image_root + card.image_name"
                  :initial-index="1"
                  fit="cover"
              />
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="toggle-container">
      <div class="tech-spec">
        <div class="collapse-header" @click="showTechSpec = !showTechSpec">
          <div class="title">TECHNICAL SPECIFICATIONS</div>
          <div class="toggle-icon">
            <ion-icon
                v-if="!showTechSpec"
                name="add-outline">
            </ion-icon>
            <ion-icon
                v-if="showTechSpec"
                name="remove-outline">
            </ion-icon>
          </div>
        </div>
        <div class="collapse-content">
          <expand-toggle>
            <div class="content-wrapper" v-if="showTechSpec">
              <ul>
                <li v-for="p in product.tech_spec.split('\n')">{{ p }}</li>
              </ul>
            </div>
          </expand-toggle>
        </div>
      </div>
      <div class="box-content">
        <div class="collapse-header" @click="showBoxContent = !showBoxContent">
          <div class="title">CONTENTS OF THE BOX</div>
          <div class="toggle-icon">
            <ion-icon
                v-if="!showBoxContent"
                name="add-outline">
            </ion-icon>
            <ion-icon
                v-if="showBoxContent"
                name="remove-outline">
            </ion-icon>
          </div>
        </div>
        <div class="collapse-content">
          <expand-toggle>
            <div class="content-wrapper" v-if="showBoxContent">
              <ul>
                <li v-for="p in product.box_content.split('\n')">{{ p }}</li>
              </ul>
            </div>
          </expand-toggle>
        </div>
      </div>
    </div>
  </div>

  <Footer></Footer>
</template>

<style lang="scss" scoped>
.background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #FFFFFF;
  z-index: -1;
}
.skeleton {
  .card {
    width: 1200px;
    height: 400px;
    margin: 10px auto;
    text-align: left;
  }
}
.content {
  .summary {
    max-width: 1140px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 80px;
    .wrapper {
      font-family: "Roboto", Sans-serif;
      display: flex;
    }
    .slide, .info {
      width: 50%;
    }
    .info {
      background: #F2F2F2;
      text-align: left;
      padding: 10px 10px 40px 10px;
      color: #1D1D1B;
      .title {
        font-size: 53px;
        font-weight: bold;
      }
      .caption {
        font-size: 33px;
        font-weight: 400;
        margin-bottom: 20px;
      }
      .spec {
        .spec-item {
          margin-top: 5px;
          padding-bottom: 5px;
        }
        .spec-icon {
          font-size: 24px;
          margin-right: 8px;
          vertical-align: middle;
        }
        .spec-text {
          color: #595959;
        }
      }
      .warranty {
        padding: 12px 0;
        margin-top: 10px;
        .warranty-wrapper {
          display: inline-block;
          border-top: 3px solid #FFFFFF;
          border-bottom: 3px solid #FFFFFF;
          padding: 10px 0;
          width: 330px;

          .icon {
            vertical-align: -4px;
            font-size: 20px;
            margin-right: 7px;
          }
        }
      }
    }
    .slide {
      padding: 10px;
      .multi-sku {
        display: flex;
        flex-wrap: wrap;
        .first-row, .second-row {
          font-weight: bold;
          text-align: center;
          width: 100%;
        }
        .chooser {
          width: 100%;
          margin-top: 15px;
          margin-bottom: 15px;
          .color {
            color: #1D1D1B;
            display: inline-block;
            height: 60px;
            width: 60px;
            border: none;
            border-radius: 60px;
            font-size: 16px;
            font-weight: 600;
            margin-right: 10px;
            line-height: 60px;
            cursor: pointer;
            transition: all .3s ease-in-out;

            &.active, &:hover {
              color: #FFFFFF;
              background: #7BC5EF;
              border-color: #7BC5EF;
            }
          }
        }
        .first-row {
          font-size: 29px;
          margin-bottom: -10px;
        }
        .second-row {
          font-size: 61px;
          color: #7BC5EF;
        }
        .images {
          height: 366px;
          width: 100%;

          .image-wrapper {
            text-align: left;
            .image-item {
              width: 33.3%;
              display: inline-block;
              padding: 5px;
              cursor: pointer;

              .el-image :deep(img) {
                transition: all .3s ease-in-out;
                transform: scale(1);
              }

              &:hover .el-image :deep(img) {
                transform: scale(1.1);
              }
            }
          }
        }
      }
      .single-sku {
        :deep(.el-carousel__indicator) {
          padding: 12px 6px;
          &.is-active button {
            background: #000000;
          }
          button {
            background: #c0c4cc;
            height: 6px;
            width: 6px;
            border-radius: 3px;
          }
        }
        :deep(.el-carousel__arrow) {
          background: transparent;
          .el-icon {
            color: #343232;
            width: 2em!important;
            height: 2em!important;

            svg {
              width: 2em!important;
              height: 2em!important;
            }
          }
        }
      }
    }
  }
  .product-card {
    .card-item {
      display: flex;
    }
    .card-image, .card-text {
      display: inline-block;
      padding: 10px;
    }
    .card-image {
      width: 55%;
    }
    .card-text {
      width: 45%;
      .text-container {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        background: #F2F2F2;
        padding-left: 25px;
      }
      .text-wrapper {
        width: 100%;
        .card-title, .card-description {
          text-align: left;
          width: 100%;
        }
        .card-title {
          color: #373A36;
          font-size: 45px;
          font-weight: 600;
          line-height: 1.2em;
          letter-spacing: 0.2px;
        }
        .card-divider {
          width: 100%;
          margin-top: 20px;
          margin-bottom: 30px;
          text-align: left;

          span {
            display: inline-block;
            width: 95px;
            height: 5px;
            background: #bad4e4;
          }
        }
        .card-description {
          color: #373A36;
          font-family: "Roboto", Sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 1.8;
        }
      }
    }
  }
  .toggle-container {
    max-width: 1140px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 10px;
  }
  .tech-spec, .box-content {
    border: 1px solid #d4d4d4;
    border-top: none;
    .collapse-header {
      padding: 20px;
      background: #4B5D4C;
      font-weight: 700;
      cursor: pointer;
      position: relative;
      .title {
        text-align: left;
        color: #FFFFFF;
        font-size: 20px;
        font-weight: 500;
        line-height: 1.2em;
        letter-spacing: 0px;
      }
      .toggle-icon {
        position: absolute;
        right: 20px;
        top: 0;
        bottom: 0;
        width: 24px;
        font-size: 24px;
        height: 24px;
        margin: auto;
        color: #373A36;
      }
    }
    .collapse-content {
      .content-wrapper {
        text-align: left;
        padding: 15px 20px;
        color: #7A7A7A;
        font-family: "Open Sans", Sans-serif;
        font-size: 14px;
      }
      ul {
        margin: 15px 0 15px 20px;
        li {
          margin-bottom: 8px;
        }
      }
    }
  }
}
@media (max-width: 576px) {
  .content {
    .summary {
      margin-bottom: 0;
      .wrapper {
        flex-wrap: wrap;
        .info, .slide {
          width: 100%;
        }
        .slide {
          padding: 20px;
          .multi-sku .images {
            height: inherit;
          }
        }
        .info {
          padding: 20px;
        }
      }
    }
    .product-card {
      .card-wrapper {
        .card-item {
          flex-wrap: wrap;
          .card-image {
            width: 100%;
            order: 1;
            padding: 0;
            background: #F2F2F2;
          }
          .card-text {
            width: 100%;
            padding: 0;
            order: 2;
            .text-container {
              padding: 20px;
            }
          }
        }
      }
    }
    .toggle-container {
      margin: 0;
      padding: 0;
    }
  }
}
</style>